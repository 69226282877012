<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ sidebarTitle }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideSideBar()"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12">
            <b-form-group
              label="Date"
              invalid-feedback="Date is required"
              ref="inc_date"
            >
              <flat-pickr
                :config="config"
                v-model="incObj.date"
                class="form-control"
                placeholder="Select date"
              />
              <!-- @on-change="checkDate()" -->
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Amount"
              invalid-feedback="Amount is required"
              ref="amount"
            >
              <b-form-input
                type="number"
                id="mc-first-name"
                placeholder="Enter amount"
                v-model="incObj.amount"
                @input="checkAmount()"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group invalid-feedback="Selection is required.">
              <div class="">
                <label class="bv-no-focus-ring col-form-label pt-0"
                  >Update Basic Salary
                  <feather-icon
                    class="cursor-pointer"
                    icon="InfoIcon"
                    size="20"
                    v-b-tooltip.hover.bottomright
                    title="This will add this increment amount to staff basic salary"
                /></label>
              </div>
              <v-select
                v-model="incObj.updateSalary"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="updateOptions"
                :reduce="(op) => op.value"
                :clearable="false"
                label="text"
                ref="upd"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Note"
              invalid-feedback="Note is required"
              ref="note"
            >
              <b-form-textarea
                ref="note"
                placeholder="Enter note"
                v-model="incObj.note"
                rows="2"
              ></b-form-textarea>
            </b-form-group>
          </b-col>

          <b-col class="mt-1" md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              class="mr-1"
              @click="Add()"
              block
              :disabled="request"
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else> {{ sidebarButton }} </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <!-- v-model="visibility2" -->
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="loanHide"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ sidebarTitle }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility2 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12" class="mb-1 d-flex">
            <b-button
              v-for="t in typeOptions"
              :key="t"
              style="width: 50%"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :variant="
                t.value == loanObj.loanType ? 'primary' : 'outline-primary'
              "
              :disabled="loanObj.id > 0"
              class="mr-1"
              @click="loanObj.loanType = t.value"
            >
              {{ t.text }}
            </b-button>
          </b-col>
          <b-col md="12" v-if="false">
            <b-form-group
              label="Date"
              invalid-feedback="Date is required"
              ref="loan_date"
            >
              <flat-pickr
                :disabled="loanObj.id > 0"
                ref="loan_date"
                :config="config"
                v-model="loanObj.date"
                class="form-control"
                placeholder="Select date"
              />
              <!-- @on-change="checkLoanDate()" -->
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Amount"
              invalid-feedback="Amount is required"
              ref="loan_amount"
            >
              <b-form-input
                :disabled="loanObj.id > 0"
                ref="loan_amount"
                type="number"
                id="mc-first-name"
                placeholder="Enter amount"
                v-model="loanObj.amount"
                @input="checkLoanAmount()"
                @focusout="setInstallment()"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Return in months"
              invalid-feedback="Invalid Month"
              ref="loan_month"
            >
              <b-form-input
                :disabled="loanObj.id > 0"
                ref="loan_month"
                type="number"
                placeholder="Enter no. of months"
                v-model="loanObj.months"
                @input="checkMonth()"
                @focusout="setInstallment()"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Installment Amount"
              invalid-feedback="Invalid Amount"
              ref="return_amount"
            >
              <b-form-input
                :disabled="loanObj.id > 0"
                ref="return_amount"
                type="number"
                placeholder="Amount to return per month"
                v-model="loanObj.monthlyInstallments"
                @input="checkRetAmount()"
                @focusout="setbyRetAmount()"
              />
            </b-form-group>
          </b-col>
          <!-- <b-col md="12">
            <b-form-group label="Deduct From" invalid-feedback="Invalid Amount">
              <v-select
                v-model="sameMonth"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="deductOption"
                label="text"
                :reduce="(opt) => opt.value"
                :clearable="false"
                placeholder="Select"
              />
            </b-form-group>
          </b-col> -->
          <b-col md="12">
            <label class="bv-no-focus-ring col-form-label pt-0"
              >Deduct From</label
            >
          </b-col>

          <b-col md="6">
            <b-form-group label="" invalid-feedback="Invalid Amount">
              <v-select
                v-model="loanMonth"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="months"
                label="text"
                :reduce="(opt) => opt.value"
                :clearable="false"
                placeholder="Select"
                :disabled="loanObj.id > 0"
                @input="setInstallment()"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="" invalid-feedback="Invalid Amount">
              <v-select
                v-model="loanYear"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="years"
                :clearable="false"
                placeholder="Select"
                :disabled="loanObj.id > 0"
                @input="setInstallment()"
              />
            </b-form-group>
          </b-col>

          <b-table
            class="mt-50"
            :items="installData"
            :fields="installFields"
            show-empty
            responsive
            hover
            small
          >
            <template #cell(month)="data">
              <b-badge variant="light-primary">
                {{ data.item.month }} {{ data.item.year }}
              </b-badge>
            </template>
            <template #cell(amount)="data">
              <b-badge variant="light-primary">
                Rs.{{ data.item.amount }}
              </b-badge>
              <b-badge
                v-if="data.item.isReturned"
                variant="light-success"
                class="ml-1"
              >
                Returned
              </b-badge>
            </template>
          </b-table>

          <b-col class="mt-1" md="12" v-if="loanObj.id == 0">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              @click="AddLoan()"
              block
              :disabled="request"
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else> {{ sidebarButton }} </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility5"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ sidebarTitle }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility5 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12" v-if="false">
            <b-form-group label="Date" invalid-feedback="Date is required">
              <flat-pickr
                :config="config"
                v-model="depoObj.date"
                class="form-control"
                placeholder="Select date"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Deposit Amount"
              invalid-feedback="Amount is required"
              ref="depo_amount"
            >
              <b-form-input
                ref="depo_amount"
                type="number"
                id="mc-first-name"
                placeholder="Enter amount"
                v-model="depoObj.amount"
                @input="checkDAmount()"
                @focusout="setDInstallment()"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Deposit Months"
              invalid-feedback="Invalid Month"
              ref="depo_month"
            >
              <b-form-input
                ref="depo_month"
                type="number"
                placeholder="Enter no. of months"
                v-model="depoObj.months"
                @input="checkDMonth()"
                @focusout="setDInstallment()"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Amount"
              invalid-feedback="Invalid Amount"
              ref="depo_return"
            >
              <b-form-input
                ref="depo_return"
                type="number"
                placeholder="Amount to return per month"
                v-model="depoObj.monthlyInstallments"
                @input="checkDRetAmount()"
                @focusout="setbyDReturn()"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <label class="bv-no-focus-ring col-form-label pt-0"
              >Deduct From</label
            >
          </b-col>

          <b-col md="6">
            <b-form-group label="" invalid-feedback="Invalid Amount">
              <v-select
                v-model="deductMonth"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="months"
                label="text"
                :reduce="(opt) => opt.value"
                :clearable="false"
                @input="GetDeductMonth()"
                placeholder="Select"
              />

              <!-- <v-select
                v-model="sameMonth"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="deductOption"
                label="text"
                :reduce="(opt) => opt.value"
                :clearable="false"
                placeholder="Select"
              /> -->
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="" invalid-feedback="Invalid Amount">
              <v-select
                v-model="deductYear"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="years"
                :clearable="false"
                placeholder="Select"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <label class="bv-no-focus-ring col-form-label pt-0 pb-1"
              >Skip Months</label
            >
          </b-col>
          <b-col md="12">
            <b-button
              class="btn-icon mr-50 mb-1"
              v-for="mon in months"
              :key="mon.value"
              :variant="skipped.includes(mon.value) ? 'danger' : 'primary'"
              @click="setSkip(mon.value)"
            >
              {{ mon.text.slice(0, 3) }}
            </b-button>
          </b-col>

          <b-col class="mt-1" md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              @click="AddDeposit()"
              block
              :disabled="request"
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else> {{ sidebarButton }} </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-Fee"
      bg-variant="white"
      v-model="visibility3"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Manage Allowances</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility3 = false"
        />
      </div>

      <b-form class="p-2" @submit.prevent>
        <b-row class="d-flex align-items-center">
          <b-col md="5" class="pr-0">
            <b-form-group label="Title" invalid-feedback="Title is required.">
              <b-form-input
                ref="title_allow"
                v-model="allowObj.title"
                placeholder="Enter title"
                @focusout="checkTitle()"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="5">
            <b-form-group label="Amount" invalid-feedback="Invalid Amount">
              <b-form-input
                ref="amount_allow"
                v-model="allowObj.amount"
                type="number"
                placeholder="Enter amount"
                @input="checkAllAmount()"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-button
              variant="success"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="SaveAllow()"
              :disabled="saving"
            >
              <b-spinner v-if="saving" small type="grow" />
              <feather-icon v-else :icon="accIcon" />
            </b-button>
          </b-col>
        </b-row>
        <b-table
          class="mt-1"
          show-empty
          :items="allowances"
          :fields="allowFields"
          responsive
          hover
        >
          <template #head(actions)="data">
            <div class="text-right">
              <span>{{ data.label }}</span>
            </div>
          </template>

          <template #cell(actions)="data">
            <div class="text-right">
              <b-button
                variant="primary"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="editAllow(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                v-if="false"
                variant="outline-danger"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="deleteAllow(data.item.id)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-Fee"
      bg-variant="white"
      v-model="visibility4"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Manage Deductions</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility4 = false"
        />
      </div>

      <b-form class="p-2" @submit.prevent>
        <b-row class="d-flex align-items-center">
          <b-col md="5" class="pr-0">
            <b-form-group label="Title" invalid-feedback="Title is required.">
              <b-form-input
                ref="title_ded"
                v-model="deductObj.title"
                placeholder="Enter title"
                @focusout="checkDTitle()"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="5">
            <b-form-group label="Amount" invalid-feedback="Invalid Amount">
              <b-form-input
                ref="amount_ded"
                v-model="deductObj.amount"
                type="number"
                placeholder="Enter amount"
                @input="checkDedAmount()"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-button
              variant="success"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="SaveDeduct()"
              :disabled="saving"
            >
              <b-spinner v-if="saving" small type="grow" />
              <feather-icon v-else :icon="accIcon" />
            </b-button>
          </b-col>
        </b-row>
        <b-table
          class="mt-1"
          show-empty
          :items="deductions"
          :fields="allowFields"
          responsive
          hover
        >
          <template #head(actions)="data">
            <div class="text-right">
              <span>{{ data.label }}</span>
            </div>
          </template>

          <template #cell(actions)="data">
            <div class="text-right">
              <b-button
                variant="primary"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="editDeduct(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                v-if="false"
                variant="outline-danger"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="deleteDeduct(data.item.id)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-Fee"
      bg-variant="white"
      v-model="visibility6"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
      width="480px"
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Breakup Policy</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility6 = false"
        />
      </div>

      <b-form class="p-2" @submit.prevent>
        <b-row
          class="align-items-center mb-1 mb-sm-0"
          v-for="(item, index) in policySett"
          :key="index"
        >
          <b-col sm="6" class="pr-sm-0">
            <b-form-group
              v-if="item.allowanceTypeID == 0"
              label=""
              invalid-feedback="detail is required."
              class="mt-2"
            >
              <b-form-input placeholder="" v-model="salaryText" disabled />
            </b-form-group>
            <b-form-group
              v-else
              label="Allowance"
              invalid-feedback="detail is required."
            >
              <v-select
                v-model="item.allowanceTypeID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="allowances"
                :reduce="(op) => op.id"
                :selectable="(opt) => !SettIDs.includes(opt.id)"
                :clearable="false"
                label="title"
                @input="changeSettAllow()"
              />
            </b-form-group>
          </b-col>
          <b-col sm="3" class="pr-sm-0">
            <b-form-group
              label="Percentage"
              invalid-feedback="Percentage is required."
              ref="sett_perc"
            >
              <b-form-input
                ref="sett_perc"
                type="number"
                placeholder="Enter percentage"
                v-model="item.perc"
              />
            </b-form-group>
          </b-col>

          <b-col sm="3">
            <b-button
              v-if="index == policySett.length - 1"
              @click="addSett(item)"
              variant="primary"
              class="btn-icon rounded-circle mr-50"
            >
              <feather-icon icon="PlusIcon" />
            </b-button>
            <b-button
              v-if="policySett.length > 1 && item.allowanceTypeID != 0"
              @click="removeSett(item, index)"
              variant="outline-danger"
              class="btn-icon rounded-circle"
            >
              <feather-icon icon="XIcon" />
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col md="12">
            <b-button
              variant="success"
              class="btn-icon"
              :disabled="savingSett"
              block
              @click="saveSett()"
            >
              <b-spinner v-if="savingSett" small type="grow" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-Fee"
      bg-variant="white"
      v-model="visibility7"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Payroll Setting</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility7 = false"
        />
      </div>

      <b-form class="p-2" @submit.prevent>
        <b-row>
          <b-col
            class="d-flex align-items-center justify-content-between mb-1"
            md="12"
          >
            <label class="bv-no-focus-ring h4 mb-0 p-0 mr-1 text-dark"
              >Payroll Share Policy</label
            >
            <b-form-checkbox v-model="pCopy.valueBool" switch />
          </b-col>
          <b-col md="12" class="mt-1">
            <b-button
              variant="success"
              :disabled="savingSett"
              block
              @click="savePSett()"
            >
              <b-spinner v-if="savingSett" small type="grow" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <form-wizard
      ref="wizard"
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="steps-transparent mb-2"
      @on-complete="formSubmitted"
    >
      <!-- account detail tab   :before-change="validationForm"-->
      <tab-content title="Profile" icon="feather icon-file-text">
        <!-- :before-change="validationForm" -->
        <b-row
          class="match-height"
          style="display: flex; align-items: center; justify-content: center"
        >
          <b-col md="8">
            <b-card class="">
              <b-row align-h="center">
                <!-- <b-col cols="4"> </b-col> -->
                <b-col class="ml-3" cols="4">
                  <b-form-group
                    class="text-center"
                    ref="picture"
                    :state="nameState"
                    label=" "
                    label-for="name-input"
                    invalid-feedback="Picture is required."
                  >
                    <b-media no-body ref="picture">
                      <div v-if="logoloading == true">
                        <b-spinner
                          variant="primary"
                          type="grow"
                          class="m-5"
                          label="Spinning"
                        ></b-spinner>
                      </div>

                      <b-media-aside>
                        <b-link>
                          <b-img
                            ref="previewEl"
                            src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
                            @click="$refs.fileProfile.click()"
                            v-if="logoloading == false"
                            style="border-radius: 100px"
                            fluid
                            height="120px"
                            width="120px"
                          />

                          <!-- <img v-if= "loading==false" rounded :src="require('@/assets/images/elements/Unicorn-Cake.jpg')" @click="$refs.files.click()" style="width: 7vw; height: 7vw; padding-left:20px; padding-top:20px;" alt="imgAdd"/>  -->
                        </b-link>

                        <b-link v-if="logoloading == 'loaded'">
                          <div class="text-right" style="margin-right: 35px">
                            <b-button
                              @click="deleteLogo()"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              class="position-absolute btn-icon"
                              variant="danger"
                            >
                              <feather-icon icon="Trash2Icon" />
                            </b-button>
                          </div>

                          <b-img
                            class="rcorners2"
                            :src="plus"
                            height="120px"
                            width="120px"
                          />

                          <br />
                        </b-link>
                        <input
                          type="file"
                          id="fileProfile"
                          hidden
                          ref="fileProfile"
                          @change="selectlogo()"
                          accept="image/*"
                        />
                      </b-media-aside>
                    </b-media>
                    <small class="mr-1 text-danger" v-if="logoerror"
                      >Logo is required.</small
                    >
                  </b-form-group>
                </b-col>
                <!-- <b-col cols="4"> </b-col> -->
              </b-row>
              <!-- <h3 class="mt-1">Student Information</h3> -->
              <hr />

              <b-row>
                <b-col
                  md="6"
                  v-if="
                    reportDomain == 'myskoolhpgs' &&
                    $store.state.userData.cId == 1
                  "
                >
                  <b-form-group
                    label="Campus *"
                    invalid-feedback="Campus is required."
                    ref="camp"
                  >
                    <v-select
                      v-model="myObj.campusID"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="campuses"
                      :clearable="false"
                      :reduce="(val) => val.id"
                      label="branch"
                      placeholder="Select campus"
                      @input="CheckCampus()"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Name *"
                    invalid-feedback="Staff name is required."
                  >
                    <b-form-input
                      placeholder="Enter name."
                      ref="name"
                      v-model.trim="myObj.name"
                      @focusout="CheckName()"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group
                    label="Father name"
                    invalid-feedback="Father name is required."
                  >
                    <b-form-input
                      ref="fname"
                      placeholder="Enter father name."
                      v-model.trim="myObj.father_name"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Spouse name"
                    invalid-feedback="Spouse name is required."
                  >
                    <b-form-input
                      ref="spouse"
                      placeholder="Enter spouse name."
                      v-model="myObj.spouse"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Gender"
                    invalid-feedback="School name is required."
                  >
                    <v-select
                      v-model="myObj.gender"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="genders"
                      :clearable="false"
                      input-id="categoryId"
                      ref="category"
                      @change="checkCategory()"
                      :disabled="dataloading"
                      placeholder="Select gender"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Date of Birth">
                    <flat-pickr
                      :config="config"
                      v-model="myObj.date_of_birth"
                      class="form-control"
                      placeholder="Select date of birth."
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Contact *"
                    invalid-feedback="Contact number is required."
                  >
                    <vue-tel-input
                      v-model="myObj.contact_number"
                      :inputOptions="vueTelOptions"
                      @input="CheckSContact"
                    ></vue-tel-input>
                    <small class="mr-1 text-danger" v-if="sPhoneerror"
                      >Phone number is required</small
                    >
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="CNIC no"
                    invalid-feedback="CNIC no is required."
                  >
                    <b-form-input
                      placeholder="Enter cnic number"
                      v-model="myObj.cnic"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Qualification."
                    invalid-feedback="qualification is required."
                  >
                    <b-form-input
                      ref="qua"
                      placeholder="Enter qualification."
                      v-model="myObj.qualification"
                    />
                    <!-- @focusout="CheckQualification()" -->
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Department *"
                    invalid-feedback="department is required."
                    ref="dep"
                  >
                    <!-- style="z-index: 0" -->
                    <v-select
                      v-model="myObj.departmentID"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="deptOptions"
                      :reduce="(opt) => opt.id"
                      label="name"
                      :clearable="false"
                      @input="checkDep()"
                      :disabled="dataloading"
                      placeholder="Select department"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Designation"
                    invalid-feedback="designation is required."
                  >
                    <b-form-input
                      ref="design"
                      placeholder="Enter designation."
                      v-model="myObj.designation"
                    />
                    <!-- @focusout="CheckDesignation()" -->
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Biometric card no."
                    invalid-feedback="Card no. is required."
                  >
                    <b-form-input
                      placeholder="Enter biometric card"
                      v-model="myObj.card_number"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Experience"
                    invalid-feedback="Experience is required."
                  >
                    <b-form-input
                      placeholder="Enter experience."
                      v-model="myObj.experience"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Note"
                    invalid-feedback="Note is required."
                  >
                    <b-form-input
                      placeholder="Enter note"
                      v-model="myObj.note"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Current Address"
                    invalid-feedback="Contact number is required."
                  >
                    <b-form-input
                      placeholder="Enter current address."
                      v-model="myObj.address_current"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Permanent Address"
                    invalid-feedback="Contact number is required."
                  >
                    <b-form-input
                      placeholder="Enter permanent address."
                      v-model="myObj.address_permanent"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="4">
                  <b-form-group
                    label="Appointed on"
                    invalid-feedback="Appointment date is required"
                    ref="appoint_date"
                  >
                    <flat-pickr
                      :config="config"
                      v-model="myObj.appointedOn"
                      class="form-control"
                      placeholder="Select appointment date."
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label="Leaving Date"
                    invalid-feedback="Date is required"
                  >
                    <flat-pickr
                      :config="config"
                      v-model="myObj.leaving_date"
                      class="form-control"
                      placeholder="Select date"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label="Rejoining Date"
                    invalid-feedback="Date is required"
                  >
                    <flat-pickr
                      :config="config"
                      v-model="myObj.rejoining_date"
                      class="form-control"
                      placeholder="Select date"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <label
                    class="bv-no-focus-ring col-form-label pt-0 d-flex align-items-center"
                    >Is Coordinator?
                    <b-form-checkbox
                      v-model="myObj.isCoordinator"
                      switch
                      class="ml-1"
                    />
                  </label>
                </b-col>
                <b-col
                  md="4"
                  v-if="
                    reportDomain == 'myskoolhpgs' &&
                    $store.state.userData.cId == 1
                  "
                >
                  <label
                    class="bv-no-focus-ring col-form-label pt-0 d-flex align-items-center"
                    >Hold Salary
                    <b-form-checkbox
                      v-model="myObj.holdSalary"
                      switch
                      class="ml-1"
                    />
                  </label>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col md="8">
            <b-card class="">
              <h3 class="mt-1">Emergency Information</h3>
              <hr />
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Name"
                    invalid-feedback="Name is required."
                  >
                    <b-form-input
                      ref="emerName"
                      placeholder="Enter name."
                      v-model="myObj.emergency_contact_person"
                    />
                    <!-- @focusout="CheckEmerName()" -->
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label="Relation"
                    invalid-feedback="Relation is required."
                  >
                    <b-form-input
                      ref="emerRel"
                      placeholder="Enter relation."
                      v-model="myObj.emergency_contact_relation"
                    />
                    <!-- @focusout="CheckEmerRelation()" -->
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Emergency contact"
                    invalid-feedback="Emergency Contact is required."
                  >
                    <vue-tel-input
                      v-model="myObj.emergency_number"
                      :inputOptions="emerOpt"
                    ></vue-tel-input>
                    <!-- @blur="CheckEmerContact()" -->
                    <small class="mr-1 text-danger" v-if="emerPhoneerror"
                      >Phone number is required.</small
                    >
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row> </b-row>
            </b-card>
          </b-col>
          <b-col md="8">
            <b-card class="">
              <div class="d-flex justify-content-between">
                <h3 class="">Timings</h3>
                <!-- v-if="this.$route.params.id == 0" -->
                <div>
                  <!-- <b-button variant="outline-primary" class="btn-icon mr-50">
                    Mon to Fri
                  </b-button>
                  <b-button variant="outline-primary" class="btn-icon mr-50">
                    Mon to Sat
                  </b-button> -->
                  <b-form-checkbox
                    v-model="sameDays"
                    switch
                    inline
                    v-b-tooltip.hover.bottomright
                    title="Everyday schedule same as monday"
                    @input="setDays(timingInfo[0])"
                    class="mr-0"
                  />
                </div>
              </div>
              <hr />
              <b-row class="">
                <div
                  v-for="(item, index) in timingInfo"
                  :key="index"
                  style="display: flex; align-items: center; width: 100%"
                >
                  <b-col md="4" class="">
                    <b-form-group
                      label="Day"
                      invalid-feedback="Day is required."
                      ref="day"
                    >
                      <v-select
                        v-model="item.day"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="daysList"
                        :reduce="(op) => op.value"
                        :selectable="(opt) => !currDays.includes(opt.value)"
                        :clearable="false"
                        label="text"
                        @input="changeDay()"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="3" class="">
                    <b-form-group
                      label="Joins at"
                      invalid-feedback="Joins at is required."
                      ref="join"
                    >
                      <flat-pickr
                        v-model="item.timeIn"
                        class="form-control"
                        :config="{
                          enableTime: true,
                          noCalendar: true,
                          altFormat: 'h:i K',
                          altInput: true,
                          dateFormat: 'Y-m-d\\TH:i:S',
                          defaultHour: 8,
                        }"
                      />
                      <!-- @on-close="testing(item)" -->
                      <!-- <b-form-input
                        ref="join"
                        placeholder="Enter time"
                        v-model="item.timeIn"
                      /> -->
                    </b-form-group>
                  </b-col>
                  <b-col md="3" class="">
                    <b-form-group
                      label="Leaves at"
                      invalid-feedback="Leaves at is required."
                      ref="leave"
                    >
                      <flat-pickr
                        v-model="item.timeOut"
                        class="form-control"
                        :config="{
                          enableTime: true,
                          noCalendar: true,
                          altFormat: 'h:i K',
                          altInput: true,
                          dateFormat: 'Y-m-d\\TH:i:S',
                          defaultHour: 13,
                        }"
                        @on-close="setDays(item)"
                      />
                      <!-- <b-form-input
                        ref="leave"
                        placeholder="Enter time"
                        v-model="item.timeOut"
                      /> -->
                    </b-form-group>
                  </b-col>

                  <b-col md="2">
                    <b-button
                      v-if="index == timingInfo.length - 1"
                      @click="AddVal()"
                      variant="primary"
                      class="btn-icon rounded-circle mr-50"
                    >
                      <feather-icon size="18" icon="PlusIcon" />
                    </b-button>
                    <b-button
                      v-if="timingInfo.length > 1"
                      @click="removeVal(item, index)"
                      variant="outline-danger"
                      class="btn-icon rounded-circle"
                    >
                      <feather-icon size="18" icon="XIcon" />
                    </b-button>
                  </b-col>
                </div>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </tab-content>

      <tab-content
        title="Classes"
        icon="feather icon-smartphone"
        v-if="localID > 0"
      >
        <b-row class="mb-1">
          <b-col md="12">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="44"
                  :src="myObj.picture"
                  variant="light-primary"
                />
              </template>

              <b-link
                class="font-weight-bold d-block text-nowrap"
                style="margin-bottom: 4px"
              >
                <b-badge variant="light-primary">
                  {{ myObj.name }}
                </b-badge>
              </b-link>
              <small class="text-muted">
                <b-badge variant="light-primary">
                  {{ myObj.designation }}
                </b-badge>
              </small>
            </b-media>
          </b-col>
        </b-row>
        <b-row class="match-height">
          <!-- align-h="center"-->
          <b-col md="12">
            <b-card class="">
              <h3 class="mt-1">Teaching Classes</h3>
              <hr />
              <b-row>
                <b-col md="3">
                  <b-form-group
                    label="Class"
                    invalid-feedback="Class is required."
                    ref="tcID"
                  >
                    <v-select
                      v-model="teachingClassObj.clsID"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="classesOptions"
                      :clearable="false"
                      :reduce="(val) => val.id"
                      label="name"
                      input-id="id"
                      ref="tcID"
                      @input="FillSections()"
                      @search:blur="CheckTcID()"
                      :disabled="dataloading"
                      placeholder="Select class"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group
                    label="Section"
                    invalid-feedback="Section is required."
                    ref="tSection"
                  >
                    <!-- v-model="teachingClassObj.sections" -->
                    <v-select
                      ref="tSection"
                      multiple
                      :closeOnSelect="false"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      v-model="selectedSections"
                      :options="admittedSections"
                      :reduce="(val) => val.id"
                      label="section"
                      :clearable="false"
                      :disabled="dataloading"
                      @input="setSections()"
                      @search:blur="CheckTSection()"
                      placeholder="Select section"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label="Subject"
                    invalid-feedback="Subject is required."
                    ref="tSubjects"
                  >
                    <v-select
                      ref="tSubjects"
                      multiple
                      :closeOnSelect="false"
                      v-model="selectedSubs"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="subjects"
                      :reduce="(val) => val.id"
                      label="subject"
                      :clearable="false"
                      :disabled="dataloading"
                      @input="setSubjects()"
                      @search:blur="CheckTSubject()"
                      placeholder="Select assigned subject."
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4" v-if="false">
                  <b-form-group
                    label="Subject"
                    invalid-feedback="Subject is required."
                    ref="tSubjects"
                  >
                    <v-select
                      ref="tSubjects"
                      multiple
                      :closeOnSelect="false"
                      v-model="teachingClassObj.subjects"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="subjects"
                      :reduce="(val) => val.id"
                      label="subject"
                      :clearable="false"
                      :disabled="dataloading"
                      @input="CheckTSubject()"
                      placeholder="Select assigned subject."
                    />
                  </b-form-group>
                </b-col>
                <b-col md="1">
                  <b-form-group label="" class="mt-2">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="btn-icon"
                      variant="success"
                      @click="assign()"
                      :disabled="assigning"
                      block
                    >
                      <b-spinner v-if="assigning" small />
                      <span v-else>Save</span>
                    </b-button>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <!-- <b-table
                    class="mt-1"
                    :tbody-tr-class="rowClass"
                    ref="selectableTable"
                    :items="teachingList"
                    :fields="fields"
                    responsive
                    hover
                  >
                    <template #cell(subject_admitted)="data">
                      <b-badge
                        variant="light-primary"
                        v-for="item in data.item.subject_admitted"
                        :key="data.item"
                        style="margin: 0px 2px"
                      >
                        <span> {{ item }} , </span>
                      </b-badge>
                    </template>
                  </b-table> -->
                  <b-table
                    class="mt-1"
                    :tbody-tr-class="rowClass"
                    ref="selectableTable"
                    :items="gridData"
                    :fields="fields2"
                    responsive
                    hover
                  >
                    <template #cell(subjects)="data">
                      <b-badge
                        variant="light-primary"
                        v-for="sub in data.item.subjects"
                        :key="data.sub"
                        style="margin: 2px"
                      >
                        <span>{{ sub.subject }}</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-50"
                          @click="removeSingle(data.item, sub)"
                        />
                      </b-badge>
                    </template>
                    <template #cell(actions)="data">
                      <div class="">
                        <!-- <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                          @click="EditTeaching(data.item)"
                        >
                          <feather-icon icon="EditIcon" />
                        </b-button>

                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-danger"
                          class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                          @click="DeleteTeaching(data.item)"
                        >
                          <feather-icon icon="Trash2Icon" />
                        </b-button> -->
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-danger"
                          class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                          @click="DeleteSub(data.item)"
                          v-b-tooltip.hover.left
                          title="Delete"
                        >
                          <feather-icon icon="Trash2Icon" />
                        </b-button>
                      </div>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </tab-content>

      <tab-content
        title="App Settings"
        icon="feather icon-settings"
        v-if="localID > 0"
      >
        <b-row class="mb-1">
          <b-col md="12">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="44"
                  :src="myObj.picture"
                  variant="light-primary"
                />
              </template>

              <b-link
                class="font-weight-bold d-block text-nowrap"
                style="margin-bottom: 4px"
              >
                <b-badge variant="light-primary">
                  {{ myObj.name }}
                </b-badge>
              </b-link>
              <small class="text-muted">
                <b-badge variant="light-primary">
                  {{ myObj.designation }}
                </b-badge>
              </small>
            </b-media>
          </b-col>
        </b-row>
        <b-row class="match-height">
          <!-- align-h="center"-->
          <b-col md="12">
            <b-card class="">
              <h3 class="mt-1">Application Rights</h3>
              <hr />
              <b-row>
                <b-col class="d-flex align-items-center flex-wrap" md="12">
                  <b-form-checkbox-group
                    v-model="selectedRights"
                    :options="appRights"
                  ></b-form-checkbox-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="" class="mt-2">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      :disabled="savingRights"
                      @click="saveRights()"
                    >
                      <b-spinner v-if="savingRights" small />
                      <span v-else>Save</span>
                    </b-button>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </tab-content>

      <tab-content title="Increment History" icon="feather icon-trending-up">
        <b-row class="mb-1" v-if="localID > 0">
          <b-col md="12">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="44"
                  :src="myObj.picture"
                  variant="light-primary"
                />
              </template>

              <b-link
                class="font-weight-bold d-block text-nowrap"
                style="margin-bottom: 4px"
              >
                <b-badge variant="light-primary">
                  {{ myObj.name }}
                </b-badge>
              </b-link>
              <small class="text-muted">
                <b-badge variant="light-primary">
                  {{ myObj.designation }}
                </b-badge>
              </small>
            </b-media>
          </b-col>
        </b-row>
        <!-- v-if="localID > 0" -->
        <b-row class="match-height">
          <b-col md="12">
            <b-card class="">
              <b-row>
                <b-col>
                  <b-button
                    @click="AddOpen()"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                  >
                    <feather-icon icon="PlusIcon" class="mr-50" />
                    <span class="align-middle">Increment History</span>
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-table
                    class="mt-1"
                    :tbody-tr-class="rowClass"
                    ref="hisTable"
                    :items="history"
                    :fields="hisFields"
                    show-empty
                    responsive
                    hover
                  >
                    <template #cell(date)="data">
                      <b-badge variant="light-primary">
                        {{
                          new Date(data.value).toLocaleDateString("en-UK", {
                            year: "numeric",
                            day: "numeric",
                            month: "short",
                          })
                        }}
                      </b-badge>
                    </template>
                    <template #cell(amount)="data">
                      <b-badge variant="light-primary">
                        {{ data.value }}
                      </b-badge>
                    </template>
                    <template #cell(actions)="data">
                      <div class="">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          class="btn-icon mr-50"
                          @click="EditInc(data.item, data.index)"
                        >
                          <feather-icon icon="EditIcon" />
                        </b-button>

                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-danger"
                          class="btn-icon mr-50"
                          @click="DeleteInc(data.item.id, data.index)"
                        >
                          <feather-icon icon="Trash2Icon" />
                        </b-button>
                      </div>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </tab-content>

      <tab-content title="Payroll" icon="feather icon-dollar-sign">
        <b-row class="mb-1" v-if="localID > 0">
          <b-col md="12" sm="6">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="44"
                  :src="myObj.picture"
                  variant="light-primary"
                />
              </template>

              <b-link
                class="font-weight-bold d-block text-nowrap"
                style="margin-bottom: 4px"
              >
                <b-badge variant="light-primary">
                  {{ myObj.name }}
                </b-badge>
              </b-link>
              <small class="text-muted">
                <b-badge variant="light-primary">
                  {{ myObj.designation }}
                </b-badge>
              </small>
            </b-media>
          </b-col>
        </b-row>
        <!-- v-if="localID > 0" -->
        <b-row align-h="center" class="match-height">
          <b-col xl="6" lg="6" md="12">
            <b-card class="">
              <h3 class="mt-1">
                Allowances
                <feather-icon
                  size="18"
                  icon="EditIcon"
                  class="ml-50 cursor-pointer"
                  @click="openAllow()"
                />
              </h3>
              <hr />
              <b-row
                class="align-items-center mb-1 mb-md-0"
                v-for="(item, index) in AddingInfo"
                :key="index"
              >
                <!-- <div
                  v-for="(item, index) in AddingInfo"
                  :key="index"
                  style="display: flex; align-items: center; width: 100%"
                >
                </div> -->
                <b-col md="7" class="pr-md-0">
                  <b-form-group
                    label="Allowance"
                    invalid-feedback="detail is required."
                    ref="details"
                  >
                    <v-select
                      v-model="item.allowanceID"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="allowances"
                      :reduce="(op) => op.id"
                      :selectable="(opt) => !allowIDs.includes(opt.id)"
                      :clearable="false"
                      label="title"
                      @input="changeAllow()"
                    />
                    <!-- <b-form-input
                      ref="details"
                      placeholder="Enter details"
                      v-model="item.details"
                    /> -->
                  </b-form-group>
                </b-col>
                <b-col md="3" class="pr-md-0">
                  <b-form-group
                    label="Amount"
                    invalid-feedback="Amount is required."
                    ref="sal_amount"
                  >
                    <b-form-input
                      ref="sal_amount"
                      type="number"
                      placeholder="Enter amount"
                      v-model="item.amount"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-button
                    v-if="index == AddingInfo.length - 1"
                    @click="AddDetail(item)"
                    variant="primary"
                    class="btn-icon rounded-circle mr-50"
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                  <b-button
                    v-if="AddingInfo.length > 1"
                    @click="removeDetail(item, index)"
                    variant="outline-danger"
                    class="btn-icon rounded-circle"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col xl="6" lg="6" md="12">
            <b-card class="">
              <h3 class="mt-1">
                Salary Deduction
                <feather-icon
                  size="18"
                  icon="EditIcon"
                  class="ml-50 cursor-pointer"
                  @click="openDeduct()"
                />
              </h3>
              <hr />
              <b-row
                class="align-items-center mb-1 mb-md-0"
                v-for="(item, index) in deductInfo"
                :key="index"
              >
                <b-col md="7" class="pr-md-0">
                  <b-form-group
                    label="Deduction"
                    invalid-feedback="detail is required."
                    ref="ded_details"
                  >
                    <v-select
                      v-model="item.deductionID"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="deductions"
                      :reduce="(op) => op.id"
                      :selectable="(opt) => !dedIDs.includes(opt.id)"
                      :clearable="false"
                      label="title"
                      @input="changeDed()"
                    />
                    <!-- <b-form-input
                      ref="ded_details"
                      placeholder="Enter details"
                      v-model="item.details"
                    /> -->
                  </b-form-group>
                </b-col>
                <b-col md="3" class="pr-md-0">
                  <b-form-group
                    label="Amount"
                    invalid-feedback="Amount is required."
                    ref="ded_amount"
                  >
                    <b-form-input
                      ref="ded_amount"
                      type="number"
                      placeholder="Enter amount"
                      v-model="item.amount"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-button
                    v-if="index == deductInfo.length - 1"
                    @click="AddDeduct(item)"
                    variant="primary"
                    class="btn-icon rounded-circle mr-50"
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                  <b-button
                    v-if="deductInfo.length > 1"
                    @click="removeDeduct(item, index)"
                    variant="outline-danger"
                    class="btn-icon rounded-circle"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </b-col>
                <!-- <div
                  v-for="(item, index) in deductInfo"
                  :key="index"
                  style="display: flex; align-items: center; width: 100%"
                >
                </div> -->
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row align-h="center" class="">
          <b-col xl="12" lg="12" md="12">
            <b-card class="">
              <h3 class="d-flex align-items-center justify-content-between">
                Salary Information
                <feather-icon
                  size="22"
                  icon="SettingsIcon"
                  class="cursor-pointer"
                  @click="openSettings()"
                  v-b-tooltip.hover.top
                  title="Breakup Policy"
                />
              </h3>
              <hr />
              <b-row>
                <b-col md="4">
                  <b-form-group invalid-feedback="Salary is required.">
                    <div class="">
                      <label class="bv-no-focus-ring col-form-label pt-0"
                        >Total Salary
                        <feather-icon
                          class="ml-50 cursor-pointer"
                          icon="RefreshCwIcon"
                          size="18"
                          v-b-tooltip.hover.top
                          title="Calculate basic salary & allowances"
                          @click="calculate()"
                        />
                      </label>
                    </div>
                    <b-form-input
                      ref="salary"
                      v-model="myObj.totalSalary"
                      type="number"
                      class="form-control"
                      placeholder="Enter salary"
                      @keyup.enter="calculate()"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label="Basic Salary"
                    invalid-feedback="salary is required."
                  >
                    <b-form-input
                      ref="basic"
                      v-model="myObj.basicSalary"
                      type="number"
                      class="form-control"
                      placeholder="Enter basic salary "
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label="Leaves allowed from"
                    invalid-feedback="date is required"
                  >
                    <flat-pickr
                      :config="config"
                      v-model="myObj.leaves_allowed_from"
                      class="form-control"
                      placeholder="Select date."
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="4" v-if="reportDomain == 'myskoolskh'">
                  <b-form-group
                    label="Share"
                    invalid-feedback="Share is required."
                  >
                    <b-form-input
                      ref="share"
                      v-model="myObj.sharePerc"
                      type="number"
                      class="form-control"
                      placeholder="Enter share"
                    />
                  </b-form-group>
                </b-col>
                <b-col :md="reportDomain == 'myskoolskh' ? 8 : 12">
                  <b-form-group
                    label="Pay Mode"
                    invalid-feedback="Pay mode is required."
                  >
                    <b-form-input
                      v-model="myObj.payMode"
                      placeholder="Enter pay mode."
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Account Title"
                    invalid-feedback="Monthly fees is required."
                  >
                    <b-form-input
                      v-model="myObj.accountTitle"
                      placeholder="Enter tccount title."
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Account #"
                    invalid-feedback="Account number is required."
                  >
                    <b-form-input
                      v-model="myObj.accountNumber"
                      placeholder="Enter A/C No."
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row v-if="false">
                <b-col md="6">
                  <b-form-group
                    label="EOBI Deduction"
                    invalid-feedback="EOBI Deduction is required."
                  >
                    <b-form-input placeholder="Enter EOBI" />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Staff Welfare Fund"
                    invalid-feedback="Staff Welfare Fund is required."
                  >
                    <b-form-input placeholder="Enter staff welfare fund" />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col xl="7" lg="8" md="12">
            <b-card>
              <h3 class="d-flex align-items-center justify-content-between">
                Custom Payroll Policy
                <feather-icon
                  size="22"
                  icon="SettingsIcon"
                  class="cursor-pointer"
                  @click="openPSett()"
                  v-b-tooltip.hover.top
                  title="Settings"
                />
                <!-- <b-form-checkbox v-model="customObj.show" switch /> -->
              </h3>

              <hr />
              <b-row v-if="false">
                <b-col md="6">
                  <b-form-group
                    label="Leaves By"
                    invalid-feedback="Leaves By is required."
                    ref="leave_type"
                  >
                    <v-select
                      v-model="customObj.leaveType"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="leaveTypes"
                      :reduce="(op) => op.value"
                      :clearable="false"
                      label="text"
                      placeholder="Select"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Starting From"
                    invalid-feedback="Date is required."
                    ref="dtFrom"
                  >
                    <flat-pickr
                      ref="dtFrom"
                      :config="config"
                      v-model="customObj.dateFrom"
                      class="form-control"
                      placeholder="Select Date."
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- settings -->
              <b-row class="mt-1">
                <!-- leaves -->
                <b-col md="12">
                  <b-row>
                    <b-col
                      class="d-flex align-items-center justify-content-between mb-1"
                      md="12"
                    >
                      <label class="bv-no-focus-ring h4 mb-0 p-0 mr-1 text-dark"
                        >Allow Leaves</label
                      >
                      <b-form-checkbox v-model="leavesObj.enabled" switch />
                    </b-col>
                    <b-col md="6" v-if="leavesObj.enabled" class="mb-1">
                      <b-form-group
                        label="Category"
                        invalid-feedback="Category is required."
                        ref="all_cat"
                      >
                        <v-select
                          ref="all_cat"
                          v-model="leavesObj.option"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="leaveTypes"
                          :reduce="(opt) => opt.text"
                          label="text"
                          :clearable="false"
                          placeholder="Select"
                          @input="setMaxLeaves()"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      :md="leavesObj.option == 'Yearly' ? '3' : '6'"
                      v-if="leavesObj.enabled"
                    >
                      <b-form-group
                        label="Leave Allowed"
                        invalid-feedback="Leave Allowed is required."
                        ref="leave_allowed"
                      >
                        <b-form-input
                          placeholder="Enter leave allowed"
                          type="number"
                          ref="leave_allowed"
                          v-model="leavesObj.number"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      md="3"
                      v-if="leavesObj.enabled && leavesObj.option == 'Yearly'"
                      class="mb-50"
                    >
                      <b-form-group
                        label="Max Leaves per Month"
                        invalid-feedback="Max Allowed is required."
                        ref="max_allowed"
                      >
                        <b-form-input
                          placeholder="Enter max leaves per month"
                          type="number"
                          ref="max_allowed"
                          v-model="maxObj.number"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>

                <!-- late coming -->
                <b-col md="12">
                  <b-row>
                    <b-col
                      class="d-flex align-items-center justify-content-between mb-1"
                      md="12"
                    >
                      <label class="bv-no-focus-ring h4 mb-0 p-0 mr-1 text-dark"
                        >Late Coming Deduction</label
                      >
                      <b-form-checkbox v-model="lateObj.enabled" switch />
                    </b-col>
                    <b-col md="6" v-if="lateObj.enabled" class="mb-1">
                      <b-form-group
                        label="Late Coming Scale"
                        invalid-feedback="Invalid value entered"
                        ref="lateScale"
                      >
                        <b-form-input
                          placeholder="Enter late coming scale"
                          ref="lateScale"
                          type="number"
                          v-model="lateObj.number"
                          @input="setLateHint()"
                        />
                        <p
                          v-if="showLateHint"
                          class="mb-0 mt-50"
                          style="font-size: 12px; line-height: 1rem"
                        >
                          ({{ lateObj.number ? lateObj.number : "n" }}) late
                          comings equal to 1 absent
                        </p>
                      </b-form-group>
                    </b-col>
                    <b-col md="6" v-if="lateObj.enabled" class="mb-1">
                      <b-form-group label="Deduct For Every Late:">
                        <b-form-checkbox
                          v-model="lateObj.option"
                          switch
                          value="Yes"
                          unchecked-value="No"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>

                <!-- late coming per min -->
                <b-col md="12">
                  <b-row>
                    <b-col
                      class="d-flex align-items-center justify-content-between mb-1"
                      md="12"
                    >
                      <label class="bv-no-focus-ring h4 mb-0 p-0 mr-1 text-dark"
                        >Late Coming Deduction (minutes)</label
                      >
                      <b-form-checkbox v-model="minuteObj.enabled" switch />
                    </b-col>
                    <b-col md="6" v-if="minuteObj.enabled" class="mb-1">
                      <b-form-group
                        label="Rupees per minute"
                        invalid-feedback="Invalid value entered"
                        ref="lateAmount"
                      >
                        <b-form-input
                          placeholder="Enter rupees per minute"
                          ref="lateAmount"
                          type="number"
                          v-model="minuteObj.number"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>

                <!-- short leave -->
                <b-col md="12">
                  <b-row>
                    <b-col
                      class="d-flex align-items-center justify-content-between mb-1"
                      md="12"
                    >
                      <label class="bv-no-focus-ring h4 mb-0 p-0 mr-1 text-dark"
                        >Short Leave Deduction</label
                      >
                      <b-form-checkbox v-model="shortObj.enabled" switch />
                    </b-col>
                    <b-col md="6" v-if="shortObj.enabled" class="mb-1">
                      <b-form-group
                        label="Short Leave Scale"
                        invalid-feedback="Invalid value entered"
                        ref="shortScale"
                      >
                        <b-form-input
                          placeholder="Enter short leave scale"
                          type="number"
                          ref="shortScale"
                          v-model="shortObj.number"
                          @input="setHalfHint()"
                        />
                        <p
                          v-if="showHalfHint"
                          class="mb-0 mt-50"
                          style="font-size: 12px; line-height: 1rem"
                        >
                          ({{ shortObj.number ? shortObj.number : "n" }}) short
                          leaves equal to 1 absent
                        </p>
                      </b-form-group>
                    </b-col>
                    <b-col md="6" v-if="shortObj.enabled" class="mb-1">
                      <b-form-group label="Deduct For Every Short Leave:">
                        <b-form-checkbox
                          v-model="shortObj.option"
                          switch
                          value="Yes"
                          unchecked-value="No"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>

                <!-- early -->
                <b-col md="12">
                  <b-row>
                    <b-col
                      class="d-flex align-items-center justify-content-between mb-1"
                      md="12"
                    >
                      <label class="bv-no-focus-ring h4 mb-0 p-0 mr-1 text-dark"
                        >Early Departure</label
                      >
                      <b-form-checkbox v-model="earlyObj.enabled" switch />
                    </b-col>
                    <b-col md="6" v-if="earlyObj.enabled" class="mb-1">
                      <b-form-group
                        label="Early Departure Scale"
                        invalid-feedback="Invalid value entered"
                        ref="earlyScale"
                      >
                        <b-form-input
                          placeholder="Enter early departure scale"
                          type="number"
                          ref="earlyScale"
                          v-model="earlyObj.number"
                          @input="setEarlyHint()"
                        />
                        <p
                          v-if="showEarly"
                          class="mb-0 mt-50"
                          style="font-size: 12px; line-height: 1rem"
                        >
                          ({{ earlyObj.number ? earlyObj.number : "n" }}) early
                          departures equal to 1 absent
                        </p>
                      </b-form-group>
                    </b-col>
                    <b-col md="6" v-if="earlyObj.enabled" class="mb-1">
                      <b-form-group label="Deduct For Every Early Departure:">
                        <b-form-checkbox
                          v-model="earlyObj.option"
                          switch
                          value="Yes"
                          unchecked-value="No"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>

                <!-- attendance -->
                <b-col md="12">
                  <b-row>
                    <b-col
                      class="d-flex align-items-center justify-content-between mb-1"
                      md="12"
                    >
                      <label class="bv-no-focus-ring h4 mb-0 p-0 mr-1 text-dark"
                        >100% Attendance Allowance</label
                      >
                      <b-form-checkbox v-model="attObj.enabled" switch />
                    </b-col>
                    <b-col md="6" v-if="attObj.enabled" class="mb-1">
                      <b-form-group
                        label="Based On"
                        invalid-feedback="Attendance Allowance is required."
                        ref="att_allow"
                      >
                        <v-select
                          ref="att_allow"
                          v-model="attObj.option"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="attTypes"
                          :reduce="(opt) => opt.text"
                          label="text"
                          :clearable="false"
                          placeholder="Select"
                          @input="checkFields()"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6" v-if="attObj.enabled" class="mb-1">
                      <b-form-group
                        v-if="attObj.option == 'Fixed amount'"
                        label="Amount"
                        invalid-feedback="Invalid amount entered"
                        ref="amount"
                      >
                        <b-form-input
                          placeholder="Enter amount"
                          type="number"
                          ref="amount"
                          v-model="attObj.number"
                        />
                      </b-form-group>
                      <b-form-group
                        v-if="attObj.option == 'Percentage'"
                        label="Percentage"
                        invalid-feedback="Invalid value entered"
                        ref="percentage"
                      >
                        <b-form-input
                          placeholder="Enter percentage"
                          type="number"
                          ref="percentage"
                          v-model="attObj.number"
                        />
                      </b-form-group>
                      <!-- <b-form-group
                        v-if="attObj.option == 'one'"
                        label="No. of Days"
                        invalid-feedback="Invalid value entered"
                        ref="days"
                      >
                        <b-form-input
                          placeholder="Enter no. of days"
                          type="number"
                          ref="days"
                          v-model="attObj.number"
                        />
                      </b-form-group> -->
                    </b-col>
                  </b-row>
                </b-col>

                <!-- encashment -->
                <b-col md="12">
                  <b-row>
                    <b-col
                      class="d-flex align-items-center justify-content-between mb-1"
                      md="12"
                    >
                      <label class="bv-no-focus-ring h4 mb-0 p-0 mr-1 text-dark"
                        >Leave Encashment</label
                      >
                      <b-form-checkbox v-model="encashObj.enabled" switch />
                    </b-col>
                    <b-col md="6" v-if="encashObj.enabled" class="mb-1">
                      <b-form-group
                        label="Maximum Encashment Leaves"
                        invalid-feedback="Invalid value entered"
                        ref="maxEn"
                      >
                        <b-form-input
                          placeholder="Enter max no.of leaves"
                          type="number"
                          ref="maxEn"
                          v-model="encashObj.number"
                          @input="checkMax()"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>

                <!-- for iota -->
                <b-col
                  v-if="pObj && pObj.valueBool"
                  class="d-flex align-items-center justify-content-between mb-1"
                  md="12"
                >
                  <label class="bv-no-focus-ring h4 mb-0 p-0 mr-1 text-dark"
                    >Payroll by Share</label
                  >
                  <b-form-checkbox
                    v-model="payrollByShare"
                    switch
                    @change="setShareData()"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>

          <b-col
            xl="7"
            lg="8"
            md="12"
            v-if="pObj && pObj.valueBool && payrollByShare"
          >
            <b-card>
              <h3 class="">Payroll Share Policy</h3>
              <hr />

              <b-row v-if="pClassData.length == 0">
                <b-col md="12" class="text-center">
                  <b-button
                    @click="AddP()"
                    variant="primary"
                    class="btn-icon rounded-circle"
                  >
                    <feather-icon size="18" icon="PlusIcon" />
                  </b-button>
                  <p class="mb-0 mt-50">Add payroll share details</p>
                </b-col>
              </b-row>
              <b-row v-else v-for="(item, ind) in pClassData" :key="ind">
                <b-col md="4">
                  <b-form-group
                    label="Class"
                    invalid-feedback="Class is required."
                  >
                    <v-select
                      v-model="item.clsID"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="classesOptions"
                      :clearable="false"
                      :selectable="(opt) => !selectedCls.includes(opt.id)"
                      :reduce="(val) => val.id"
                      label="name"
                      @input="FillPSec(item, ind, '')"
                      :disabled="dataloading"
                      placeholder="Select class"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label="Section"
                    invalid-feedback="Section is required."
                  >
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      v-model="item.secID"
                      :options="pSections[ind]"
                      :reduce="(val) => val.id"
                      label="section"
                      :clearable="false"
                      :disabled="dataloading"
                      placeholder="Select section"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label="Subject"
                    invalid-feedback="Subject is required."
                  >
                    <v-select
                      v-model="item.subjectID"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="pSubjects"
                      :reduce="(val) => val.id"
                      label="subject"
                      :clearable="false"
                      :disabled="dataloading"
                      placeholder="Select subject"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label="Base Amount"
                    invalid-feedback="Base Amount is required"
                  >
                    <b-form-input
                      type="number"
                      placeholder="Enter base amount"
                      v-model="item.baseValue"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label="Share per student"
                    invalid-feedback="Share is required"
                  >
                    <b-form-input
                      type="number"
                      placeholder="Enter share amount"
                      v-model="item.sharePerStudent"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label="Starting From"
                    invalid-feedback="Date is required."
                  >
                    <flat-pickr
                      :config="config"
                      v-model="item.startingFrom"
                      class="form-control"
                      placeholder="Select date"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12" class="text-center">
                  <b-button
                    v-if="ind == pClassData.length - 1"
                    @click="AddP()"
                    variant="primary"
                    class="btn-icon rounded-circle mr-50"
                  >
                    <feather-icon size="18" icon="PlusIcon" />
                  </b-button>
                  <b-button
                    v-if="pClassData.length > 1"
                    @click="removeP(item, ind)"
                    variant="outline-danger"
                    class="btn-icon rounded-circle"
                  >
                    <feather-icon size="18" icon="XIcon" />
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </tab-content>

      <tab-content title="Security Deposit" icon="feather icon-lock">
        <b-row class="mb-1" v-if="localID > 0">
          <b-col md="12">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="44"
                  :src="myObj.picture"
                  variant="light-primary"
                />
              </template>

              <b-link
                class="font-weight-bold d-block text-nowrap"
                style="margin-bottom: 4px"
              >
                <b-badge variant="light-primary">
                  {{ myObj.name }}
                </b-badge>
              </b-link>
              <small class="text-muted">
                <b-badge variant="light-primary">
                  {{ myObj.designation }}
                </b-badge>
              </small>
            </b-media>
          </b-col>
        </b-row>
        <!-- v-if="false" -->
        <b-row class="match-height">
          <b-col xl="3" lg="3" md="6" sm="6">
            <b-card>
              <h4 class="card-title">Salary</h4>
              <p class="card-text font-small-6 font-weight-bolder mb-0">
                <span>Rs.</span
                ><span class="font-weight-bolder mb-0">
                  <!-- {{ totalDeposits }} -->
                  {{ myObj.totalSalary }}
                </span>
              </p>
            </b-card>
          </b-col>
          <b-col xl="3" lg="3" md="6" sm="6">
            <b-card>
              <h4 class="card-title">Deposited so far</h4>
              <p class="card-text font-small-6 font-weight-bolder mb-0">
                <span>Rs.</span
                ><span class="font-weight-bolder mb-0">
                  {{ currentDeposits }}</span
                >
              </p>
            </b-card>
          </b-col>
          <b-col xl="3" lg="3" md="6" sm="6">
            <b-card>
              <h4 class="card-title">Remaining Deposits</h4>
              <p
                class="card-text font-small-6 font-weight-bolder mb-0 d-flex justify-content-between"
              >
                <span class="font-weight-bolder mb-0">
                  Rs. {{ remainingDeposits }}</span
                >
                <b-badge
                  variant="light-danger"
                  v-if="myObj.totalSalary > totalDeposits"
                  >{{ myObj.totalSalary - totalDeposits }}</b-badge
                >
              </p>
            </b-card>
          </b-col>
          <b-col xl="3" lg="3" md="6" sm="6">
            <b-card>
              <h4 class="card-title">Returned Deposits</h4>
              <p class="card-text font-small-6 font-weight-bolder mb-0">
                <span>Rs.</span
                ><span class="font-weight-bolder mb-0">
                  {{ returnDeposits }}</span
                >
              </p>
            </b-card>
          </b-col>

          <b-col md="12">
            <b-card class="">
              <b-row>
                <b-col md="12" class="d-flex justify-content-between flex-wrap">
                  <b-button
                    @click="depoOpen()"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mt-50 mt-sm-0"
                  >
                    <feather-icon icon="PlusIcon" class="mr-50" />
                    <span class="align-middle">Security Deposit</span>
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mt-50 mt-sm-0"
                    :disabled="delAll"
                    @click="depoDelAll()"
                  >
                    <span class="align-middle">Delete undeposited</span>
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-table
                    class="mt-1"
                    ref="depoTable"
                    :tbody-tr-class="rowClass"
                    :items="deposits"
                    :fields="depoFields"
                    show-empty
                    responsive
                    hover
                  >
                    <template #cell(date)="data">
                      <b-badge variant="light-primary">
                        <!-- {{
                          new Date(data.value).toLocaleDateString("en-UK", {
                            year: "numeric",
                            day: "numeric",
                            month: "short",
                          })
                        }} -->
                        {{ data.item.month }} {{ data.item.year }}
                      </b-badge>
                    </template>
                    <template #cell(amount)="data">
                      <b-badge variant="light-primary">
                        {{ data.value }}
                      </b-badge>
                      <!-- <b-badge
                        v-if="data.item.isReturned != null"
                        variant="light-success"
                        class="ml-1"
                      >
                        Returned
                      </b-badge>
                      <b-badge
                        v-else-if="data.item.salaryID != null"
                        variant="light-success"
                        class="ml-1"
                      >
                        Deposited
                      </b-badge> -->
                    </template>
                    <template #cell(loanType)="data">
                      <b-badge variant="light-primary">
                        {{ data.value }}
                      </b-badge>
                    </template>

                    <template #cell(actions)="data">
                      <b-button
                        v-if="data.item.salaryID != null"
                        variant="success"
                        class="btn-icon mr-1"
                        pill
                        size="sm"
                        @click="markUnDeposit(data.item)"
                      >
                        Deposited
                      </b-button>
                      <b-button
                        v-else
                        variant="outline-success"
                        class="btn-icon mr-1"
                        pill
                        size="sm"
                        @click="markDeposit(data.item)"
                      >
                        Deposit
                      </b-button>
                      <b-button
                        v-if="
                          data.item.isReturned != null &&
                          data.item.isReturned == true
                        "
                        variant="success"
                        class="btn-icon mr-1"
                        pill
                        size="sm"
                        @click="markUnReturned(data.item)"
                      >
                        Returned
                      </b-button>
                      <b-button
                        v-else-if="
                          data.item.isReturned == null &&
                          data.item.salaryID != null
                        "
                        variant="outline-success"
                        class="btn-icon mr-1"
                        pill
                        size="sm"
                        @click="markReturned(data.item)"
                      >
                        Returned
                      </b-button>

                      <!-- <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="btn-icon mr-50"
                        @click="EditDepo(data.item, data.index)"
                      >
                        <feather-icon icon="EditIcon" />
                      </b-button> -->

                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-danger"
                        class="btn-icon mr-50"
                        @click="DeleteDepo(data.item.id, data.index)"
                        v-b-tooltip.hover.right
                        title="Delete"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </tab-content>

      <tab-content title="Loan / Advance " icon="feather icon-info">
        <b-row class="mb-1" v-if="localID > 0">
          <b-col md="12">
            <b-media vertical-align="center">
              <template #aside>
                <b-button
                  v-if="visibility2"
                  @click="visibility2 = false"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-secondary"
                  class="btn-icon rounded-circle mr-50"
                  v-b-tooltip.hover.top
                  title="Back"
                >
                  <feather-icon icon="ArrowLeftIcon" size="20" />
                </b-button>
                <b-avatar
                  size="44"
                  :src="myObj.picture"
                  variant="light-primary"
                />
              </template>

              <b-link
                class="font-weight-bold d-block text-nowrap"
                style="margin-bottom: 4px"
              >
                <b-badge variant="light-primary">
                  {{ myObj.name }}
                </b-badge>
              </b-link>
              <small class="text-muted">
                <b-badge variant="light-primary">
                  {{ myObj.designation }}
                </b-badge>
              </small>
            </b-media>
          </b-col>
        </b-row>
        <!-- v-if="false" -->
        <b-row class="match-height">
          <b-col md="12">
            <b-card class="" v-if="visibility2">
              <b-row>
                <b-col md="12">
                  <b-button
                    v-for="t in typeOptions"
                    :key="t"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    :variant="
                      t.value == loanObj.loanType
                        ? 'primary'
                        : 'outline-primary'
                    "
                    :disabled="loanObj.id > 0"
                    class="mr-1 mb-1"
                    @click="loanObj.loanType = t.value"
                  >
                    {{ t.text }}
                  </b-button>
                </b-col>
                <b-col lg="3" md="4">
                  <b-form-group
                    label="Amount"
                    invalid-feedback="Amount is required"
                    ref="loan_amount"
                  >
                    <b-form-input
                      :disabled="loanObj.id > 0"
                      ref="loan_amount"
                      type="number"
                      id="mc-first-name"
                      placeholder="Enter amount"
                      v-model="loanObj.amount"
                      @input="checkLoanAmount()"
                      @focusout="setbyRetAmount()"
                    />
                  </b-form-group>
                </b-col>
                <b-col lg="3" md="4">
                  <b-form-group
                    label="Installment Amount"
                    invalid-feedback="Invalid Amount"
                    ref="return_amount"
                  >
                    <b-form-input
                      :disabled="loanObj.id > 0"
                      ref="return_amount"
                      type="number"
                      placeholder="Amount to return per month"
                      v-model="loanObj.monthlyInstallments"
                      @input="checkRetAmount()"
                      @focusout="setbyRetAmount()"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label="Deduct From"
                    invalid-feedback="Invalid Amount"
                  >
                    <div class="d-flex">
                      <v-select
                        v-model="loanMonth"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="months"
                        label="text"
                        :reduce="(opt) => opt.value"
                        :clearable="false"
                        placeholder="Select"
                        :disabled="loanObj.id > 0"
                        @input="setbyRetAmount()"
                        style="flex: 1"
                        class="pr-1"
                      />
                      <v-select
                        v-model="loanYear"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="years"
                        :clearable="false"
                        placeholder="Select"
                        :disabled="loanObj.id > 0"
                        @input="setbyRetAmount()"
                        style="flex: 1"
                      />
                    </div>
                  </b-form-group>
                </b-col>
                <b-col lg="2" md="3" class="mt-lg-2" v-if="loanObj.id == 0">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    @click="AddLoan()"
                    block
                    :disabled="request"
                  >
                    <b-spinner v-if="request" small type="grow" />
                    <span v-else> {{ sidebarButton }} </span>
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-table
                    class="mt-1"
                    :items="installData"
                    :fields="installFields"
                    show-empty
                    responsive
                    hover
                  >
                    <template #cell(month)="data">
                      <b-badge variant="light-primary">
                        {{ data.item.month }} {{ data.item.year }}
                      </b-badge>
                    </template>
                    <template #cell(amount)="data">
                      <b-badge variant="light-primary">
                        Rs.{{ data.item.amount }}
                      </b-badge>
                      <b-badge
                        v-if="data.item.isReturned"
                        variant="light-success"
                        class="ml-1"
                      >
                        Returned
                      </b-badge>
                    </template>

                    <template #cell(actions)="data">
                      <b-badge
                        v-if="data.item.isReturned"
                        variant="light-success"
                        class=""
                      >
                        Returned
                      </b-badge>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-card>
            <b-card class="" v-else>
              <b-row>
                <b-col>
                  <b-button
                    @click="LoanOpen()"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                  >
                    <feather-icon icon="PlusIcon" class="mr-50" />
                    <span class="align-middle">Loan / Advance </span>
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-table
                    class="mt-1"
                    ref="loanTable"
                    :tbody-tr-class="rowClass"
                    :items="loanData"
                    :fields="loanFields"
                    show-empty
                    responsive
                    hover
                  >
                    <template #cell(date)="data">
                      <b-badge variant="light-primary">
                        {{
                          new Date(data.value).toLocaleDateString("en-UK", {
                            year: "numeric",
                            month: "short",
                          })
                        }}
                      </b-badge>
                    </template>
                    <template #cell(amount)="data">
                      <b-badge variant="light-primary">
                        {{ data.value }}
                      </b-badge>
                    </template>
                    <template #cell(loanType)="data">
                      <b-badge variant="light-primary">
                        {{ data.value }}
                      </b-badge>
                    </template>
                    <template #cell(months)="data">
                      <b-badge variant="light-primary">
                        {{ data.value }}
                      </b-badge>
                    </template>
                    <template #cell(actions)="data">
                      <div class="">
                        <!-- v-if="data.item.id > 0" -->
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          class="btn-icon mr-50"
                          @click="EditLoan(data.item, data.index)"
                        >
                          <feather-icon icon="EyeIcon" />
                        </b-button>

                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-danger"
                          class="btn-icon mr-50"
                          @click="DeleteLoan(data.item, data.index)"
                        >
                          <feather-icon icon="Trash2Icon" />
                        </b-button>
                      </div>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </tab-content>

      <tab-content title="Documents" icon="feather icon-link">
        <b-row align-h="center">
          <b-col md="8">
            <b-row class="mb-1" v-if="localID > 0">
              <b-col md="12">
                <b-media vertical-align="center">
                  <template #aside>
                    <b-avatar
                      size="44"
                      :src="myObj.picture"
                      variant="light-primary"
                    />
                  </template>

                  <b-link
                    class="font-weight-bold d-block text-nowrap"
                    style="margin-bottom: 4px"
                  >
                    <b-badge variant="light-primary">
                      {{ myObj.name }}
                    </b-badge>
                  </b-link>
                  <small class="text-muted">
                    <b-badge variant="light-primary">
                      {{ myObj.designation }}
                    </b-badge>
                  </small>
                </b-media>
              </b-col>
            </b-row>
            <div class="file-uploader">
              <h5>
                <b-spinner
                  variant="dark"
                  label="Spinning"
                  v-if="uploading"
                ></b-spinner>
                <span v-else> Upload Documents </span>
              </h5>
              <input
                ref="docs"
                type="file"
                class="file_upload"
                @change="uploadDocument()"
                multiple="true"
              />
            </div>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col md="8">
            <template v-for="(file, index) in documents">
              <div class="file">
                <div style="display: flex; align-items: center">
                  <div class="file-type">
                    {{ file.split(".").pop().toUpperCase() }}
                  </div>
                  <b-link :href="file" target="_blank">
                    {{ file }}
                  </b-link>
                  <!-- <a class="file-name">{{ file }}</a> -->
                </div>
                <div class="file-delete">
                  <i
                    class="fa fa-trash"
                    aria-hidden="true"
                    @click="deleteDocument(index)"
                  ></i>
                </div>
              </div>
            </template>
          </b-col>
        </b-row>
      </tab-content>
      <template slot="footer" slot-scope="props">
        <div class="wizard-footer-left d-none"></div>
        <div class="wizard-footer-right d-none"></div>
      </template>
    </form-wizard>

    <b-row align-h="center">
      <b-col md="6">
        <b-button
          block
          variant="success"
          :disabled="submitting"
          @click="formSubmitted()"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Ripple from "vue-ripple-directive";
import { mapActions, mapGetters } from "vuex";
// import DropZone from 'dropzone-vue';
import vue2Dropzone from "vue2-dropzone";

import "vue2-dropzone/dist/vue2Dropzone.min.css";
// // optionally import default styles
// import 'dropzone-vue/dist/dropzone-vue.common.css';

// createApp(App)
//   .use(DropZone)
//   .mount('#app');
// import 'dropzone-vue/dist/dropzone-vue.common.css';

// optionally import default styles

import {
  VBTooltip,
  BRow,
  BFormCheckboxGroup,
  BFormCheckbox,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BButton,
  BMediaAside,
  BImg,
  BMedia,
  BLink,
  BSpinner,
  BBadge,
  BFormFile,
  BFormTextarea,
  BTable,
  BForm,
  BSidebar,
  BAvatar,
} from "bootstrap-vue";
import { resolve } from "path";
import { reject } from "q";
export default {
  components: {
    vueDropzone: vue2Dropzone,
    // DropZone,
    BAvatar,
    BForm,
    BFormTextarea,
    BSidebar,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    flatPickr,
    BCard,
    BButton,
    BMediaAside,
    BImg,
    BMedia,
    BLink,
    BSpinner,
    BBadge,
    BFormFile,
    BTable,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormCheckbox,
    BFormCheckboxGroup,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    let yList = [];
    for (let i = 2020; i <= 2040; i++) {
      yList.push(i);
    }
    return {
      skipped: [],
      delAll: false,
      years: yList,
      months: [
        { text: "January", value: 1 },
        { text: "February", value: 2 },
        { text: "March", value: 3 },
        { text: "April", value: 4 },
        { text: "May", value: 5 },
        { text: "June", value: 6 },
        { text: "July", value: 7 },
        { text: "August", value: 8 },
        { text: "September", value: 9 },
        { text: "October", value: 10 },
        { text: "November", value: 11 },
        { text: "December", value: 12 },
      ],
      deductMonth: new Date().getMonth() + 1,
      deductYear: new Date().getFullYear(),
      loanMonth: new Date().getMonth() + 1,
      loanYear: new Date().getFullYear(),
      localID: 0,
      file: [],
      mydocs: [],
      moreloading: false,
      plus: "",
      logoloading: false,
      netAmount: 0,
      dataloading: false,
      deptOptions: [],
      admittedSections: [],
      currentSections: [],
      classes: [],
      genders: ["Male", "Female"],
      groups: ["A", "B", "O", "AB"],
      religions: ["Islam", "Christianity", "Hinduism", "Buddhists"],
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
        // disableMobile: true,
      },
      myObj: {
        id: 0,
        rfID: "",
        name: "",
        father_name: "",
        designation: "",
        gender: "",
        picture: "",
        date_of_birth: null,
        address_current: "",
        address_permanent: "",
        contact_number: "",
        emergency_contact_person: "",
        emergency_contact_relation: "",
        emergency_number: "",
        qualification: "",
        experience: "",
        campusID: this.$store.state.userData.cId,
        departmentID: 0,
        documents: "",
        appointedOn: null,
        totalSalary: 0,
        payMode: null,
        accountTitle: null,
        accountNumber: null,
        cnic: "",
        spouse: "",
        isCoordinator: false,
        leaves_allowed_from: null,
        card_number: null,
        holdSalary: false,
      },
      pClassData: [],
      pSubjects: [],
      pSections: [],
      selectedCls: [],
      leaveTypes: [
        { text: "Monthly", value: "monthly" },
        { text: "Yearly", value: "yearly" },
      ],
      customObj: {
        show: false,
        leaveType: "",
        dateFrom: null,
      },
      settObj: {
        isAllowed: false,
        leaveAllowed: 0,
        category: "monthly",
        dateFrom: null,
        dateTo: null,
        onLate: false,
        lateScale: null,
        everyLate: false,
        onLeave: false,
        shortScale: null,
        everyLeave: false,
        onAttendance: false,
        attendanceType: "fixed",
        days: 0,
        amount: 0,
        percentage: 0,
        onEncashment: false,
        maxEncashment: 0,
      },
      leavesObj: {
        id: 0,
        tID: this.$route.params.id,
        categoy: "Leave Allowed",
        enabled: false,
        number: 0,
        option: "Monthly",
        campusID: this.$store.state.userData.cId,
      },
      maxObj: {
        id: 0,
        tID: this.$route.params.id,
        categoy: "Max Leave per Month",
        enabled: false,
        number: 0,
        option: "",
        campusID: this.$store.state.userData.cId,
      },
      minuteObj: {
        id: 0,
        tID: this.$route.params.id,
        categoy: "Late Coming Deduction per Minute",
        enabled: false,
        number: 0,
        option: "",
        campusID: this.$store.state.userData.cId,
      },
      earlyObj: {
        id: 0,
        tID: this.$route.params.id,
        categoy: "Early Departure",
        enabled: false,
        number: 0,
        option: "No",
        campusID: this.$store.state.userData.cId,
      },
      showEarly: true,
      lateObj: {
        id: 0,
        tID: this.$route.params.id,
        categoy: "Late Coming Scale",
        enabled: false,
        number: 0,
        option: "No",
        campusID: this.$store.state.userData.cId,
      },
      shortObj: {
        id: 0,
        tID: this.$route.params.id,
        categoy: "Short Leave Scale",
        enabled: false,
        number: 0,
        option: "No",
        campusID: this.$store.state.userData.cId,
      },
      attObj: {
        id: 0,
        tID: this.$route.params.id,
        categoy: "Attendance Allowance",
        enabled: false,
        number: 0,
        option: "Fixed amount",
        campusID: this.$store.state.userData.cId,
      },
      encashObj: {
        id: 0,
        tID: this.$route.params.id,
        categoy: "Leave Encashment",
        enabled: false,
        number: 0,
        option: "",
        campusID: this.$store.state.userData.cId,
      },
      settingData: [],
      showHalfHint: true,
      showLateHint: true,
      attTypes: [
        { text: "Fixed amount", value: "fixed" },
        { text: "Percentage", value: "percentage" },
        { text: "One day salary", value: "one" },
      ],
      classObj: {
        class_admitted: null,
        section_admitted: null,
        subject_admitted: null,
      },
      allowObj: {
        id: 0,
        title: "",
        amount: 0,
        campusID: this.$store.state.userData.cId,
      },
      allowances: [],
      allowFields: [
        { label: "title", key: "title" },
        { key: "actions", label: "actions" },
      ],
      deductObj: {
        id: 0,
        title: "",
        amount: 0,
        campusID: this.$store.state.userData.cId,
      },
      deductions: [],
      saving: false,
      accIcon: "",
      fields: [
        { label: "Class", key: "class_admitted" },
        { label: "section", key: "section_admitted" },
        { label: "subject", key: "subject_admitted" },
      ],
      // for newTeachingList
      // fields2: [
      //   { label: "Class", key: "className" },
      //   { label: "section", key: "sectionName" },
      //   { label: "subject", key: "subjects" },
      //   "actions",
      // ],
      fields2: [
        { label: "Class", key: "cls" },
        { label: "section", key: "sec" },
        { label: "subject", key: "subjects" },
        "actions",
      ],
      hisFields: [
        { label: "date", key: "date" },
        { label: "amount", key: "amount" },
        { label: "note", key: "note" },
        "actions",
      ],
      history: [],
      deposits: [],
      depoSaveList: [],
      depoObj: {},
      newDepo: true,
      depoIndex: null,
      deductOption: [
        { text: "Same Month", value: true },
        { text: "Next Month", value: false },
      ],
      sameMonth: true,
      loanFields: [
        { label: "date", key: "date" },
        { label: "type", key: "loanType" },
        { label: "amount", key: "amount" },
        { label: "months", key: "months" },
        // { label: "return", key: "monthlyInstallments" },
        "actions",
      ],
      depoFields: [
        { label: "date", key: "date" },
        { label: "amount", key: "amount" },
        // { label: "months", key: "months" },
        "actions",
      ],
      installFields: [
        { label: "month", key: "month" },
        { label: "amount", key: "amount" },
        // "actions",
      ],
      installData: [],
      loanData: [],
      loanDetails: [],
      typeOptions: [
        { text: "Loan", value: "loan" },
        { text: "Advance", value: "advance" },
      ],
      updateOptions: [
        { text: "Yes", value: "yes" },
        { text: "No", value: "no" },
      ],
      loanObj: {},
      newLoan: true,
      loanIndex: null,
      visibility2: false,
      loanHide: false,
      visibility3: false,
      visibility4: false,
      visibility5: false,
      allowIDs: [],
      dedIDs: [],
      AddingInfo: [
        {
          id: 0,
          tID: this.$route.params.id,
          amount: 0,
          allowanceID: 0,
          campusID: this.$store.state.userData.cId,
        },
      ],
      deductInfo: [
        {
          id: 0,
          tID: this.$route.params.id,
          amount: 0,
          deductionID: 0,
          campusID: this.$store.state.userData.cId,
        },
      ],

      classesOptions: [],
      teachingList: [],
      teachingClasses: [],
      newTeachingList: [],
      gridData: [],
      // teachingClassObj: {
      //   cID: 0,
      //   sectionName: "",
      //   subjects: 0,
      // },
      teachingClassObj: {
        tID: this.$route.params.id,
        clsID: 0,
        sections: [],
        subjects: [],
        campusID: this.$store.state.userData.cId,
      },
      selectedSubs: [],
      selectedSections: [],
      fphoneerror: false,
      mphoneerror: false,
      phoneerror: false,
      emerPhoneerror: false,
      sPhoneerror: false,
      fileProfile: "",
      vueTelOptions: { placeholder: "Enter contact number" },
      emerOpt: { placeholder: "Enter emergency contact" },
      documents: [],
      uploading: false,
      incObj: {},
      newInc: true,
      incIndex: null,
      sidebarTitle: "",
      sidebarButton: "",
      visibility: false,
      request: false,
      currDays: [],
      sameDays: this.$store.state.sameTimings,
      timingInfo: [
        {
          id: 0,
          tID: this.$route.params.id,
          day: "monday",
          timeIn: "",
          timeOut: "",
          campusID: this.$store.state.userData.cId,
        },
      ],
      daysList: [
        { text: "Monday", value: "monday" },
        { text: "Tuesday", value: "tuesday" },
        { text: "Wednesday", value: "wednesday" },
        { text: "Thursday", value: "thursday" },
        { text: "Friday", value: "friday" },
        { text: "Saturday", value: "saturday" },
        { text: "Sunday", value: "sunday" },
      ],
      assigning: false,
      submitting: false,
      // appRights: [
      //   "Homework",
      //   "Attendance",
      //   "Examination",
      //   "Resources",
      //   "Online Class",
      //   "Complaint",
      //   "Parent Consent",
      // ],
      appRights: [
        { text: "Homework", value: "Homework" },
        { text: "Attendance", value: "Attendance" },
        { text: "Examination", value: "Examination" },
        { text: "Resources", value: "Resources" },
        { text: "Online Class", value: "Online Class" },
        { text: "Complaint", value: "Complaint" },
        { text: "Parent Consent", value: "Parent Consent" },
      ],
      selectedRights: [
        "Homework",
        "Attendance",
        "Examination",
        "Resources",
        "Online Class",
        "Complaint",
        "Parent Consent",
      ],
      savingRights: false,
      rightObj: {},
      visibility6: false,
      policySett: [],
      breakupData: [],
      SettIDs: [],
      salaryText: "Basic Salary",
      savingSett: false,
      subObj: null,
      pObj: null,
      payrollByShare: false,
      pCopy: {},
      visibility7: false,
      reportDomain: this.$store.state.report.replace(/_/g, ""),
      campuses: [],
    };
  },
  mounted() {
    this.$refs.wizard.activateAll();
    // window.addEventListener("keydown", async (event) => {
    // });
    window.addEventListener("keydown", this.handleEvent);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleEvent);
  },
  created() {
    // console.log("----------", this.$route.params.id);
    // console.log(this.$store.state.rights["manage-staffgrid"]);
    let right = this.$store.state.rights["manage-staffgrid"];
    if (!right) {
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.$route.params.id = parseInt(this.$route.params.id);

      if (this.$route.params.id > 0 && right.edit) {
        this.localID = this.$route.params.id;
        this.LoadData(this.$route.params.id);
        // will be called after staff load
        // this.LoadInfo();
        // this.LoadSettings();
        this.LoadTeachingClasses();
        this.loadAllowances();
        this.loadDeductions();
        this.loadAppRights();
        this.loadBreakup();
        if (
          this.reportDomain == "myskoolhpgs" &&
          this.$store.state.userData.cId == 1
        ) {
          this.LoadCampuses();
        }
        // this.LoadInc();
        // this.LoadLoan();
      } else if (this.$route.params.id == 0 && right.add) {
        this.LoadDep();
        this.LoadSettings();
        this.LoadInfo();
        this.loadAllowances();
        this.loadDeductions();
        this.loadBreakup();
        if (
          this.reportDomain == "myskoolhpgs" &&
          this.$store.state.userData.cId == 1
        ) {
          this.LoadCampuses();
        }
        //this.loadAllowances("set");
        //this.loadDeductions("set");
        // this.AddingInfo = [];
        // this.deductInfo = [];
        this.loanData = [];
        this.history = [];
        this.deposits = [];
        // console.log(this.myObj);
      } else {
        this.$router.replace({
          name: "misc-not-authorized",
        });
      }
    }
    // console.log(this.$store.state.campusID);
  },
  computed: {
    ...mapGetters({ campusID: "campusID" }),

    totalDeposits() {
      let total = this.deposits.reduce((sum, el) => sum + el.amount, 0);
      return total;
    },
    currentDeposits() {
      let total = this.deposits.reduce(
        (sum, el) => (el.salaryID != null ? sum + el.amount : sum + 0),
        0
      );
      return total;
    },
    remainingDeposits() {
      return this.totalDeposits - this.currentDeposits;
    },
    returnDeposits() {
      let total = this.deposits.reduce(
        (sum, el) =>
          el.isReturned != null && el.isReturned == true
            ? sum + el.amount
            : sum + 0,
        0
      );
      return total;
    },
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({
      get: "get",
      post: "post",
      postObj: "postObj",
      put: "put",
      delete: "delete",
    }),

    openPSett() {
      if (this.pObj) {
        this.pCopy = { ...this.pObj };
      } else {
        this.pCopy = {
          id: 0,
          campusID: this.$store.state.userData.cId,
          key: "payroll_share",
          value: "payroll_share",
          valueBool: false,
          valueNumber: null,
        };
      }
      this.visibility7 = true;
      // console.log(this.pCopy);
    },
    async savePSett() {
      this.savingSett = true;
      var status = await this.post({
        url:
          this.$store.state.domain +
          "Settings/SaveNew?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        body: [this.pCopy],
        message: "Settings saved successfully.",
        context: this,
        token: this.$store.state.userData.token,
      });
      this.savingSett = false;
      if (status) {
        this.LoadSettings("check");
        this.visibility7 = false;
      }
    },

    async LoadPSubs() {
      var obj = {
        url:
          this.$store.state.domain +
          "Subjects?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.pSubjects = await this.get(obj);
    },
    FillPSec(item, ind, text) {
      if (text != "filled") item.secID = 0;
      let cls = this.classes.find((el) => el.cls.id == item.clsID);
      if (cls) this.pSections.splice(ind, 1, cls.sections);
      else this.pSections.splice(ind, 1, []);
      // console.log(this.pSections);
      // this.allowCls()
    },
    allowCls() {
      let data = [];
      this.pClassData.forEach((elem) => {
        let cls = this.classes.find((el) => el.cls.id == elem.clsID);
        if (cls && cls.section.length > 0) {
        }
      });
      this.selectedCls = data;
    },
    AddP() {
      this.pClassData.push({
        id: 0,
        tID: this.$route.params.id,
        clsID: 0,
        secID: 0,
        subjectID: 0,
        baseValue: 0,
        startingFrom: null,
        sharePerStudent: 0,
        campusID: this.$store.state.userData.cId,
      });
      this.pSections.push([]);
    },
    async removeP(item, ind) {
      if (item.id == 0) {
        this.pClassData.splice(ind, 1);
        this.pSections.splice(ind, 1);
      } else {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "staffClassShare/" +
            item.id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          grading: true,
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) {
          this.pClassData.splice(ind, 1);
          this.pSections.splice(ind, 1);
        }
      }
      // console.log(this.pSections);
    },
    setShareData() {
      // console.log(this.payrollByShare);
      if (this.payrollByShare && this.pClassData.length == 0) {
        this.AddP();
      }
    },

    async loadBreakup() {
      var obj = {
        url:
          this.$store.state.domain +
          "SalaryBreakupPolicy?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.breakupData = await this.get(obj);
      // console.log(this.breakupData);
    },
    async LoadCampuses() {
      var obj = {
        url:
          this.$store.state.domain +
          "campuses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.campuses = await this.get(obj);
    },
    openSettings() {
      this.visibility6 = true;
      if (this.breakupData.length == 0) {
        this.policySett = [
          {
            id: 0,
            allowanceTypeID: 0,
            perc: 100,
            campusID: this.$store.state.userData.cId,
          },
        ];
      } else this.policySett = this.breakupData.map((el) => ({ ...el }));
      // console.log(this.breakupData);
      this.changeSettAllow();
    },
    changeSettAllow() {
      this.SettIDs = this.policySett.reduce((acc, el) => {
        acc.push(el.allowanceTypeID);
        return acc;
      }, []);
    },
    addSett(item) {
      this.policySett.push({
        id: 0,
        allowanceTypeID: null,
        perc: 0,
        campusID: this.$store.state.userData.cId,
      });
      // console.log(this.policySett);
    },
    async removeSett(item, ind) {
      if (item.id == 0) {
        this.policySett.splice(ind, 1);
        this.changeSettAllow();
      } else {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "SalaryBreakupPolicy/" +
            item.id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          grading: true,
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) {
          this.policySett.splice(ind, 1);
          this.breakupData.splice(ind, 1);
          this.changeSettAllow();
        }
      }
    },
    checkSett() {
      let state = true;
      this.policySett.forEach((el) => {
        el.perc = parseFloat(el.perc);
        if (el.allowanceTypeID == null || isNaN(el.perc) || el.perc < 0) {
          state = false;
        }
      });
      return state;
    },
    checkSum() {
      let total = this.policySett.reduce((sum, el) => sum + el.perc, 0);
      return total == 100;
    },
    async saveSett() {
      if (this.checkSett() == false) {
        this.$bvToast.toast("Please enter the details correctly!", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
      } else if (this.checkSum() == false) {
        this.$bvToast.toast("The total % should be equal to 100.", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
      } else {
        // console.log(this.policySett);
        this.savingSett = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "SalaryBreakupPolicy/saveData?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: this.policySett,
          message: "Settings saved successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.savingSett = false;
        if (status) {
          this.loadBreakup();
          this.visibility6 = false;
        }
      }
    },
    calculate() {
      if (this.breakupData.length > 0) {
        if (this.checkSalary() == true) {
          // console.log(this.breakupData);
          this.myObj.totalSalary = parseInt(this.myObj.totalSalary);

          this.breakupData.forEach((el) => {
            if (el.allowanceTypeID == 0) {
              this.myObj.basicSalary = parseInt(
                ((this.myObj.totalSalary * el.perc) / 100).toFixed(2)
              );
            } else {
              let obj = this.AddingInfo.find(
                (elem) => elem.allowanceID == el.allowanceTypeID
              );
              // console.log(obj);

              if (obj) {
                let ind = this.AddingInfo.findIndex(
                  (elem) => elem.allowanceID == el.allowanceTypeID
                );
                obj.amount = parseInt(
                  ((this.myObj.totalSalary * el.perc) / 100).toFixed(2)
                );

                this.AddingInfo.splice(ind, 1, obj);
              } else {
                let amount = parseInt(
                  ((this.myObj.totalSalary * el.perc) / 100).toFixed(2)
                );

                this.AddingInfo.push({
                  id: 0,
                  tID: this.$route.params.id,
                  amount: amount,
                  allowanceID: el.allowanceTypeID,
                  campusID: this.$store.state.userData.cId,
                });
                this.changeAllow();
              }
            }
          });
        }
      } else {
        this.$bvToast.toast("Breakup Policy is not defined.", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
      }
    },

    async saveRights() {
      this.rightObj.rights = this.selectedRights.join(",");
      // console.log(this.selectedRights, this.rightObj);
      if (this.rightObj.id == 0) {
        this.savingRights = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "TeachersAppRights?db=" +
            this.$store.state.userData.db,
          body: this.rightObj,
          message: "App rights saved successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.savingRights = false;
        if (status) this.loadAppRights();
      } else {
        this.savingRights = true;
        var status = await this.put({
          url:
            this.$store.state.domain +
            "TeachersAppRights/" +
            this.rightObj.id +
            "?db=" +
            this.$store.state.userData.db,
          message: "App rights saved successfully.",
          context: this,
          body: this.rightObj,
          token: this.$store.state.userData.token,
        });
        this.savingRights = false;
        if (status) this.loadAppRights();
      }
    },
    async loadAppRights() {
      var obj = {
        url:
          this.$store.state.domain +
          "TeachersAppRights/LoadData?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&tID=" +
          this.$route.params.id,
        token: this.$store.state.userData.token,
      };
      this.rightObj = await this.get(obj);
      // console.log(this.rightObj);
      if (!this.rightObj) {
        this.rightObj = {
          id: 0,
          tID: this.$route.params.id,
          rights: "",
          campusID: this.$store.state.userData.cId,
        };
        this.selectedRights = [
          "Homework",
          "Attendance",
          "Examination",
          "Resources",
          "Online Class",
          "Complaint",
          "Parent Consent",
        ];
      } else {
        this.selectedRights = this.rightObj.rights.split(",");
      }
    },
    testing(item) {
      console.log(item.timeIn);
      console.log(item.timeOut);
    },
    handleEvent(event) {
      // console.log(event.key);
      if (event.ctrlKey && event.keyCode === 83) {
        event.preventDefault();
        if (!this.submitting) {
          this.submitting = true;
          this.formSubmitted();
        }
      }
    },
    changeDay() {
      this.currDays = this.timingInfo.reduce((acc, el) => {
        acc.push(el.day);
        return acc;
      }, []);
      // console.log(this.currDays);
    },
    setDays(item) {
      this.$store.commit("setSameTimings", this.sameDays);

      if (
        this.timingInfo.length == 1 &&
        this.sameDays &&
        item.day == "monday"
      ) {
        // console.log(item);
        let days = ["tuesday", "wednesday", "thursday", "friday", "saturday"];
        let data = [item];
        days.forEach((el) => {
          data.push({
            id: 0,
            tID: this.$route.params.id,
            day: el,
            timeIn: item.timeIn,
            timeOut: item.timeOut,
            campusID: this.$store.state.userData.cId,
          });
        });
        this.timingInfo = data;
        this.changeDay();
      }
    },
    CheckTimings() {
      let state = true;
      this.timingInfo = this.timingInfo.filter(
        (el) => el.day != "" && el.timeIn != "" && el.timeOut != ""
      );
      this.timingInfo.forEach((el) => {
        if (el.timeIn == "" || el.timeOut == "") {
          state = false;
        }

        // if (el.timeIn == "") {
        //   state = false;
        // } else {
        //   el.timeIn = "2023-01-01T" + el.timeIn;
        // }
        // if (el.timeOut == "") {
        //   state = false;
        // } else {
        //   el.timeOut = "2023-01-01T" + el.timeOut;
        // }
      });
      // console.log(state);
      return state;
    },
    AddVal() {
      this.timingInfo.push({
        id: 0,
        tID: this.$route.params.id,
        day: "",
        timeIn: "",
        timeOut: "",
        campusID: this.$store.state.userData.cId,
      });
      // console.log(this.timingInfo);
    },
    async removeVal(item, ind) {
      if (item.id == 0) {
        this.timingInfo.splice(ind, 1);
        // console.log(this.timingInfo);
        this.currDays = this.currDays.filter((el) => el != item.day);
      } else {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "StaffTimings/" +
            item.id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          grading: true,
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) {
          this.timingInfo.splice(ind, 1);
          this.currDays = this.currDays.filter((el) => el != item.day);
        }
        // console.log(this.timingInfo);
      }
    },
    changeAllow() {
      this.allowIDs = this.AddingInfo.reduce((acc, el) => {
        acc.push(el.allowanceID);
        return acc;
      }, []);
      // console.log(this.allowIDs);
    },
    changeDed() {
      this.dedIDs = this.deductInfo.reduce((acc, el) => {
        acc.push(el.deductionID);
        return acc;
      }, []);
      // console.log(this.dedIDs);
    },
    async loadAllowances(text) {
      var obj = {
        url:
          this.$store.state.domain +
          "Allowances?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.allowances = await this.get(obj);
      if (text == "set") {
        let data1 = [];
        this.allowances.forEach((el) => {
          data1.push({
            id: 0,
            tID: this.$route.params.id,
            amount: !el.amount ? 0 : el.amount,
            allowanceID: el.id,
            campusID: this.$store.state.userData.cId,
          });
        });
        this.AddingInfo = data1;
        this.changeAllow();
      }
      //  else if (text == "new") {
      //   this.AddingInfo.push({
      //     id: 0,
      //     tID: this.$route.params.id,
      //     amount: this.allowances.at(-1).amount,
      //     allowanceID: this.allowances.at(-1).id,
      //     campusID: this.$store.state.userData.cId,
      //   });
      // }
    },

    openAllow() {
      this.accIcon = "PlusIcon";
      this.allowObj = {
        id: 0,
        title: "",
        amount: 0,
        campusID: this.$store.state.userData.cId,
      };

      this.visibility3 = true;
      var elem = this.$refs["title_allow"];
      elem.state = undefined;
      var elem = this.$refs["amount_allow"];
      elem.state = undefined;
    },
    editAllow(item) {
      this.allowObj = { ...item };
      this.accIcon = "Edit2Icon";
      var elem = this.$refs["title_allow"];
      elem.state = undefined;
      var elem = this.$refs["amount_allow"];
      elem.state = undefined;
      // console.log(this.allowObj);
    },

    async deleteAllow(id) {
      // console.log(acc);
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "Allowances/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          message: "Allowance deleted successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.loadAllowances();
      }
    },
    checkTitle() {
      var elem = this.$refs["title_allow"];
      if (this.allowObj.title.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkAllAmount() {
      var elem = this.$refs["amount_allow"];
      if (
        parseInt(this.allowObj.amount) < 0 ||
        isNaN(parseInt(this.allowObj.amount))
      ) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },

    async SaveAllow() {
      // console.log("obj:", this.allowObj);
      if (this.checkTitle() == true && this.checkAllAmount() == true) {
        this.saving = true;
        this.allowObj.amount = parseInt(this.allowObj.amount);

        if (this.allowObj.id == 0) {
          var status = await this.post({
            url:
              this.$store.state.domain +
              "Allowances?db=" +
              this.$store.state.userData.db,
            body: this.allowObj,
            message: "Allowance added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.saving = false;
        } else {
          var status = await this.put({
            url:
              this.$store.state.domain +
              "Allowances/" +
              this.allowObj.id +
              "?db=" +
              this.$store.state.userData.db,
            message: "Allowance updated successfully.",
            context: this,
            body: this.allowObj,
            token: this.$store.state.userData.token,
          });
          this.saving = false;
        }
        if (status) {
          // let newval = "";
          // if (this.allowObj.id == 0) newval = "new";
          this.loadAllowances();
          var elem = this.$refs["title_allow"];
          elem.state = undefined;
          var elem = this.$refs["amount_allow"];
          elem.state = undefined;
          this.allowObj = {
            id: 0,
            title: "",
            campusID: this.$store.state.userData.cId,
          };
          this.accIcon = "PlusIcon";
        }
      }
    },

    async loadDeductions(text) {
      var obj = {
        url:
          this.$store.state.domain +
          "Deductions?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.deductions = await this.get(obj);
      this.deductions = this.deductions.filter((el) => !el.isHidden);

      if (text == "set") {
        let data2 = [];
        this.deductions.forEach((el) => {
          data2.push({
            id: 0,
            tID: this.$route.params.id,
            amount: !el.amount ? 0 : el.amount,
            deductionID: el.id,
            campusID: this.$store.state.userData.cId,
          });
        });
        this.deductInfo = data2;
        this.changeDed();
      }
    },

    openDeduct() {
      this.accIcon = "PlusIcon";
      this.deductObj = {
        id: 0,
        title: "",
        amount: 0,
        campusID: this.$store.state.userData.cId,
      };

      this.visibility4 = true;
      var elem = this.$refs["title_ded"];
      elem.state = undefined;
      var elem = this.$refs["amount_ded"];
      elem.state = undefined;
    },
    editDeduct(item) {
      this.deductObj = { ...item };
      this.accIcon = "Edit2Icon";
      var elem = this.$refs["title_ded"];
      elem.state = undefined;
      var elem = this.$refs["amount_ded"];
      elem.state = undefined;
      // console.log(this.deductObj);
    },

    async deleteDeduct(id) {
      // console.log(acc);
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "Deductions/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          message: "Deduction deleted successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.loadDeductions();
      }
    },
    checkDTitle() {
      var elem = this.$refs["title_ded"];
      if (this.deductObj.title.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkDedAmount() {
      var elem = this.$refs["amount_ded"];
      if (
        parseInt(this.deductObj.amount) < 0 ||
        isNaN(parseInt(this.deductObj.amount))
      ) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async SaveDeduct() {
      // console.log("obj:", this.deductObj);
      if (this.checkDTitle() == true && this.checkDedAmount() == true) {
        this.saving = true;
        this.deductObj.amount = parseInt(this.deductObj.amount);

        if (this.deductObj.id == 0) {
          var status = await this.post({
            url:
              this.$store.state.domain +
              "Deductions?db=" +
              this.$store.state.userData.db,
            body: this.deductObj,
            message: "Deduction added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.saving = false;
        } else {
          var status = await this.put({
            url:
              this.$store.state.domain +
              "Deductions/" +
              this.deductObj.id +
              "?db=" +
              this.$store.state.userData.db,
            message: "Deduction updated successfully.",
            context: this,
            body: this.deductObj,
            token: this.$store.state.userData.token,
          });
          this.saving = false;
        }
        if (status) {
          this.loadDeductions();
          var elem = this.$refs["title_ded"];
          elem.state = undefined;
          var elem = this.$refs["amount_ded"];
          elem.state = undefined;
          this.deductObj = {
            id: 0,
            title: "",
            campusID: this.$store.state.userData.cId,
          };
          this.accIcon = "PlusIcon";
        }
      }
    },
    CheckValues() {
      let state = true;
      this.AddingInfo = this.AddingInfo.filter((el) => el.allowanceID != 0);
      this.AddingInfo.forEach((el) => {
        el.amount = parseInt(el.amount);
        if (isNaN(el.amount) || el.amount < 0) {
          state = false;
        }
      });

      this.deductInfo = this.deductInfo.filter((el) => el.deductionID != 0);
      this.deductInfo.forEach((el) => {
        el.amount = parseInt(el.amount);
        if (isNaN(el.amount) || el.amount < 0) {
          state = false;
        }
      });

      return state;
    },

    AddDetail(item) {
      if (item.allowanceID != 0 && item.amount !== "") {
        this.AddingInfo.push({
          id: 0,
          tID: this.$route.params.id,
          amount: 0,
          allowanceID: 0,
          campusID: this.$store.state.userData.cId,
        });
      }
      // console.log(this.AddingInfo);
    },
    async removeDetail(item, ind) {
      if (item.id == 0) {
        this.AddingInfo.splice(ind, 1);
        console.log(this.AddingInfo);
        this.changeAllow();
        // this.allowIDs = this.allowIDs.filter((el) => el != item.allowanceID);
      } else {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "StaffAllowances/" +
            item.id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          grading: true,
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) {
          this.AddingInfo.splice(ind, 1);
          // this.allowIDs = this.allowIDs.filter((el) => el != item.allowanceID);
          this.changeAllow();
        }
        // console.log(this.AddingInfo);
      }
    },
    AddDeduct(item) {
      if (item.deductionID != 0 && item.amount !== "") {
        this.deductInfo.push({
          id: 0,
          tID: this.$route.params.id,
          amount: 0,
          deductionID: 0,
          campusID: this.$store.state.userData.cId,
        });
      }
      // console.log(this.deductInfo);
    },
    async removeDeduct(item, ind) {
      if (item.id == 0) {
        this.deductInfo.splice(ind, 1);
        console.log(this.deductInfo);
        this.changeDed();
        // this.dedIDs = this.dedIDs.filter((el) => el != item.deductionID);
      } else {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "StaffDeductions/" +
            item.id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          grading: true,
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) {
          this.deductInfo.splice(ind, 1);
          // this.dedIDs = this.dedIDs.filter((el) => el != item.deductionID);
          this.changeDed();
        }
        // console.log(this.deductInfo);
      }
    },
    AddOpen() {
      this.incObj = {
        id: 0,
        tID: this.$route.params.id,
        amount: 0,
        note: "",
        date: new Date().toJSON(),
        updateSalary: "no",
        campusID: this.$store.state.userData.cId,
      };
      this.newInc = true;
      this.visibility = true;
      this.sidebarTitle = "Add Increment";
      this.sidebarButton = "Save";
      var elem = this.$refs["amount"];
      elem.state = undefined;
      var elem = this.$refs["note"];
      elem.state = undefined;
      var elem = this.$refs["inc_date"];
      elem.state = undefined;
    },
    EditInc(item, ind) {
      console.log(ind);
      this.incObj = { ...item };
      this.newInc = false;
      this.incIndex = ind;
      this.visibility = true;
      this.sidebarTitle = "Edit Increment";
      this.sidebarButton = "Update";
      var elem = this.$refs["amount"];
      elem.state = undefined;
      var elem = this.$refs["note"];
      elem.state = undefined;
      var elem = this.$refs["inc_date"];
      elem.state = undefined;
    },

    async DeleteInc(id, ind) {
      if (id == 0) {
        this.history.splice(ind, 1);
        console.log(this.history);
      } else {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "StaffIncrementHistory/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          message: "Increment removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.history.splice(ind, 1);
        console.log(this.history);

        // let result = await Swal.fire({
        //   title: "Are you sure?",
        //   text: "You won't be able to revert this!",
        //   icon: "warning",
        //   showCancelButton: true,
        //   confirmButtonColor: "#d33",
        //   cancelButtonColor: "#3085d6",
        //   confirmButtonText: "Yes, Delete it!",
        // });

        // if (result.isConfirmed) {
        //   // var status = await this.delete({
        //   //   url:
        //   //     this.$store.state.domain +
        //   //     "?db=" +
        //   //     this.$store.state.userData.db,
        //   //   body: item,
        //   //   message: "Increment removed successfully.",
        //   //   context: this,
        //   //   token: this.$store.state.userData.token,
        //   // });
        //   // if (status) this.LoadInc();
        // }
      }
    },
    depoOpen() {
      this.depoObj = {
        id: 0,
        tID: this.$route.params.id,
        amount: this.myObj.totalSalary,
        loanType: "security deposit",
        date: new Date(),
        months: 12,
        monthlyInstallments: null,
        campusID: this.$store.state.userData.cId,
      };
      this.depoObj.monthlyInstallments = parseInt(
        this.depoObj.amount / this.depoObj.months
      );
      this.deductMonth = new Date().getMonth() + 1;
      this.deductYear = new Date().getFullYear();

      this.skipped = [];

      this.visibility5 = true;
      this.newDepo = true;
      this.sidebarTitle = "Add Deposit";
      this.sidebarButton = "Save";
      var elem = this.$refs["depo_amount"];
      elem.state = undefined;
      var elem = this.$refs["depo_month"];
      elem.state = undefined;
      var elem = this.$refs["depo_return"];
      elem.state = undefined;
    },
    EditDepo(item, ind) {
      this.depoObj = { ...item };
      this.depoIndex = ind;
      this.newDepo = false;
      this.visibility5 = true;
      this.sidebarTitle = "Edit Deposit";
      this.sidebarButton = "Update";
      var elem = this.$refs["depo_amount"];
      elem.state = undefined;
      var elem = this.$refs["depo_month"];
      elem.state = undefined;
      var elem = this.$refs["depo_return"];
      elem.state = undefined;
    },
    async DeleteDepo(id, ind) {
      if (id == 0) {
        this.deposits.splice(ind, 1);
        // console.log(this.deposits);
      } else {
        let result = await Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Yes, Delete it!",
        });

        if (result.isConfirmed) {
          var status = await this.delete({
            url:
              this.$store.state.domain +
              "StaffAdvaceAndLoansInstallmentPlan/" +
              id +
              "?db=" +
              this.$store.state.userData.db,
            message: "Deposit removed successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          if (status) this.deposits.splice(ind, 1);
          // console.log(this.deposits);
        }
      }
    },
    async depoDelAll() {
      if (this.deposits.length > 0) {
        let result = await Swal.fire({
          title: "Are you sure?",
          text: "This will delete all Undeposited amount!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Yes, Delete it!",
        });

        if (result.isConfirmed) {
          this.delAll = true;

          if (this.$route.params.id > 0) {
            var status = await this.post({
              url:
                this.$store.state.domain +
                "Staff/ClearDeposits?db=" +
                this.$store.state.userData.db +
                "&cID=" +
                this.$store.state.userData.cId +
                "&tID=" +
                this.$route.params.id,
              message: "Deposits removed successfully.",
              context: this,
              savingMarks: true,
              token: this.$store.state.userData.token,
            });
            this.delAll = false;

            this.deposits = status;
          } else {
            this.deposits = this.deposits.filter(
              (el) => el.isReturned != null || el.salaryID != null
            );
            this.delAll = false;
          }
        }
      }
    },

    LoanOpen() {
      this.loanObj = {
        id: 0,
        tID: this.$route.params.id,
        amount: null,
        loanType: "loan",
        date: new Date(),
        months: null,
        monthlyInstallments: null,
        campusID: this.$store.state.userData.cId,
        records: [],
      };
      this.installData = [];
      this.loanMonth = new Date().getMonth() + 1;
      this.loanYear = new Date().getFullYear();
      this.visibility2 = true;
      this.newLoan = true;
      this.sidebarTitle = "Add Loan / Advance ";
      this.sidebarButton = "Save";
      var elem = this.$refs["loan_amount"];
      elem.state = undefined;
      var elem = this.$refs["depo_return"];
      elem.state = undefined;
      var elem = this.$refs["loan_month"];
      elem.state = undefined;
      var elem = this.$refs["return_amount"];
      elem.state = undefined;
    },
    EditLoan(item, ind) {
      // console.log(item);
      this.loanObj = { ...item };
      let date = new Date(this.loanObj.date);
      this.loanMonth = date.getMonth() + 1;
      this.loanYear = date.getFullYear();
      if (item.id == 0) this.installData = item.records;
      else
        this.installData = this.loanDetails.filter(
          (el) => el.advanceAndLoanID == item.id
        );
      this.loanIndex = ind;
      this.newLoan = false;
      this.visibility2 = true;
      this.sidebarTitle = "Edit Loan / Advance ";
      this.sidebarButton = "Update";
      var elem = this.$refs["loan_amount"];
      elem.state = undefined;
      var elem = this.$refs["loan_date"];
      elem.state = undefined;
      var elem = this.$refs["loan_month"];
      elem.state = undefined;
      var elem = this.$refs["return_amount"];
      elem.state = undefined;
    },

    async DeleteLoan(item, ind) {
      if (item.id == 0) {
        this.loanData.splice(ind, 1);
        // console.log(this.loanData);
      } else {
        let result = await Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Yes, Delete it!",
        });

        if (result.isConfirmed) {
          var status = await this.delete({
            url:
              this.$store.state.domain +
              "StaffAdvaceAndLoans/" +
              item.id +
              "?db=" +
              this.$store.state.userData.db,
            message: item.loanType + " removed successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          if (status) this.loanData.splice(ind, 1);
          // console.log(this.loanData);
        }
      }
    },
    checkLoanDate() {
      var elem = this.$refs["loan_date"];
      if (this.loanObj.date == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkDAmount() {
      var elem = this.$refs["depo_amount"];
      if (this.depoObj.amount <= 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkDMonth() {
      var elem = this.$refs["depo_month"];
      if (this.depoObj.months <= 0) {
        // || this.depoObj.months > 12
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkDRetAmount() {
      var elem = this.$refs["depo_return"];
      if (
        this.depoObj.monthlyInstallments <= 0 ||
        parseInt(this.depoObj.monthlyInstallments) >
          parseInt(this.depoObj.amount)
      ) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    setDInstallment() {
      if (this.checkDAmount() == true && this.checkDMonth() == true) {
        this.depoObj.monthlyInstallments = parseInt(
          this.depoObj.amount / this.depoObj.months
        );

        // console.log(this.depoObj.monthlyInstallments);
      }
    },
    setbyDReturn() {
      if (this.checkDAmount() == true && this.checkDRetAmount() == true) {
        this.depoObj.amount = parseInt(this.depoObj.amount);
        this.depoObj.monthlyInstallments = parseInt(
          this.depoObj.monthlyInstallments
        );

        this.depoObj.months = Math.ceil(
          this.depoObj.amount / this.depoObj.monthlyInstallments
        );
      }
    },
    setSkip(val) {
      if (this.skipped.includes(val)) {
        this.skipped = this.skipped.filter((el) => el != val);
      } else this.skipped.push(val);
      // console.log(this.skipped);
    },
    async AddDeposit() {
      // this.checkLoanDate() == true &&
      // console.log(this.deductMonth, this.deductYear);
      if (
        this.checkDAmount() == true &&
        this.checkDMonth() == true &&
        this.checkDRetAmount() == true
      ) {
        this.request = true;
        this.depoObj.amount = parseInt(this.depoObj.amount);
        this.depoObj.months = parseInt(this.depoObj.months);
        this.depoObj.monthlyInstallments = parseInt(
          this.depoObj.monthlyInstallments
        );

        let status = false;
        this.depoObj.date = new Date(
          `${this.deductYear}-${this.deductMonth}-05`
        ).toJSON();
        // console.log(this.deductYear, this.deductMonth);
        //alert(this.depoObj.date);

        if (this.$route.params.id == 0) {
          status = await this.post({
            url:
              this.$store.state.domain +
              "staff/InstallmentsDep?db=" +
              this.$store.state.userData.db +
              "&sameMonth=true&skip=" +
              this.skipped,
            body: this.depoObj,
            subjects: true,
            context: this,
            token: this.$store.state.userData.token,
          });
        } else {
          status = await this.post({
            url:
              this.$store.state.domain +
              "Staff/DepositInstallments?db=" +
              this.$store.state.userData.db +
              "&month=" +
              this.deductMonth +
              "&year=" +
              this.deductYear +
              "&skip=" +
              this.skipped,
            body: this.depoObj,
            subjects: true,
            context: this,
            token: this.$store.state.userData.token,
          });
        }
        // console.log(status);
        if (status) {
          this.deposits = [...this.deposits, ...status];
          this.depoSaveList.push(this.depoObj);
        }
        this.request = false;
        this.visibility5 = false;

        // if (this.newDepo) this.deposits.push(this.depoObj);
        // else this.deposits[this.depoIndex] = this.depoObj;

        // this.visibility5 = false;
        // this.$refs.depoTable.refresh();
        // console.log(this.deposits);
      }
    },
    checkLoanAmount() {
      var elem = this.$refs["loan_amount"];
      if (this.loanObj.amount <= 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkMonth() {
      var elem = this.$refs["loan_month"];
      if (this.loanObj.months <= 0) {
        //  || this.loanObj.months > 12
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async GetDeductMonth() {
      console.log("MONTH---------", this.deductMonth);
    },
    async setInstallment() {
      if (this.checkLoanAmount() == true && this.checkMonth() == true) {
        this.loanObj.monthlyInstallments = parseInt(
          this.loanObj.amount / this.loanObj.months
        );

        // console.log(this.loanObj.monthlyInstallments);
        this.checkRetAmount();

        this.loanObj.amount = parseInt(this.loanObj.amount);
        this.loanObj.months = parseInt(this.loanObj.months);
        this.loanObj.monthlyInstallments = parseInt(
          this.loanObj.monthlyInstallments
        );

        this.loanObj.date = new Date(
          `${this.loanYear}-${this.loanMonth}-05`
        ).toJSON();
        // console.log(this.loanObj);
        var status = await this.post({
          url:
            this.$store.state.domain +
            "staff/Installments?db=" +
            this.$store.state.userData.db +
            "&sameMonth=" +
            this.sameMonth,
          body: this.loanObj,
          subjects: true,
          context: this,
          token: this.$store.state.userData.token,
        });
        // console.log(status);
        if (status) {
          this.installData = status;
          this.loanObj.records = status;
        }
      }
    },
    async setbyRetAmount() {
      if (this.checkLoanAmount() == true && this.checkRetAmount() == true) {
        this.loanObj.amount = parseInt(this.loanObj.amount);
        // this.loanObj.months = parseInt(this.loanObj.months);

        this.loanObj.months = Math.ceil(
          this.loanObj.amount / this.loanObj.monthlyInstallments
        );

        // console.log(this.loanObj.months);
        // this.checkMonth(); // ====

        this.loanObj.monthlyInstallments = parseInt(
          this.loanObj.monthlyInstallments
        );

        this.loanObj.date = new Date(
          `${this.loanYear}-${this.loanMonth}-05`
        ).toJSON();
        // console.log(this.loanObj);
        var status = await this.post({
          url:
            this.$store.state.domain +
            "staff/Installments?db=" +
            this.$store.state.userData.db +
            "&sameMonth=" +
            this.sameMonth,
          body: this.loanObj,
          subjects: true,
          context: this,
          token: this.$store.state.userData.token,
        });
        // console.log(status);
        if (status) {
          this.installData = status;
          this.loanObj.records = status;
        }
      }
    },
    checkRetAmount() {
      var elem = this.$refs["return_amount"];
      if (
        this.loanObj.monthlyInstallments <= 0 ||
        parseInt(this.loanObj.monthlyInstallments) >
          parseInt(this.loanObj.amount)
      ) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async markReturned(item) {
      if (item.id == 0) {
        item.isReturned = true;
      } else {
        item.isReturned = true;
        // console.log(item);

        var status = await this.put({
          url:
            this.$store.state.domain +
            "StaffAdvaceAndLoansInstallmentPlan/" +
            item.id +
            "?db=" +
            this.$store.state.userData.db,
          token: this.$store.state.userData.token,
          message: "Marked as Returned",
          context: this,
          body: item,
          token: this.$store.state.userData.token,
        });
        if (status) {
          console.log("done");
        }
      }
    },
    async markDeposit(item) {
      if (item.id == 0) {
        item.salaryID = 0;
      } else {
        item.salaryID = 0;

        var status = await this.put({
          url:
            this.$store.state.domain +
            "StaffAdvaceAndLoansInstallmentPlan/" +
            item.id +
            "?db=" +
            this.$store.state.userData.db,
          token: this.$store.state.userData.token,
          message: "Marked as Deposited",
          context: this,
          body: item,
          token: this.$store.state.userData.token,
        });
        if (status) {
          console.log("done");
        }
      }
    },
    async markUnDeposit(item) {
      if (item.id == 0) {
        item.salaryID = null;
        item.isReturned = null;
      } else {
        let result = await Swal.fire({
          title: "Are you sure?",
          text: "Do you want to undeposit this amount?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Yes!",
        });

        if (result.isConfirmed) {
          item.salaryID = null;
          item.isReturned = null;
          // console.log(item);

          var status = await this.put({
            url:
              this.$store.state.domain +
              "StaffAdvaceAndLoansInstallmentPlan/" +
              item.id +
              "?db=" +
              this.$store.state.userData.db,
            token: this.$store.state.userData.token,
            message: "Marked as Undeposit",
            context: this,
            body: item,
            token: this.$store.state.userData.token,
          });
          if (status) {
            console.log("done");
          }
        }
      }
    },
    async markUnReturned(item) {
      if (item.id == 0) {
        item.isReturned = null;
      } else {
        let result = await Swal.fire({
          title: "Are you sure?",
          text: "Do you want to unreturn this deposit?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Yes!",
        });

        if (result.isConfirmed) {
          item.isReturned = null;
          // console.log(item);

          var status = await this.put({
            url:
              this.$store.state.domain +
              "StaffAdvaceAndLoansInstallmentPlan/" +
              item.id +
              "?db=" +
              this.$store.state.userData.db,
            token: this.$store.state.userData.token,
            message: "Marked as unreturned",
            context: this,
            body: item,
            token: this.$store.state.userData.token,
          });
          if (status) {
            console.log("done");
          }
        }
      }
    },
    async AddLoan() {
      // this.checkLoanDate() == true &&
      if (
        this.checkLoanAmount() == true &&
        this.checkMonth() == true &&
        this.checkRetAmount() == true
      ) {
        this.loanObj.amount = parseInt(this.loanObj.amount);
        this.loanObj.months = parseInt(this.loanObj.months);
        this.loanObj.monthlyInstallments = parseInt(
          this.loanObj.monthlyInstallments
        );
        if (this.newLoan) this.loanData.push(this.loanObj);
        else this.loanData[this.loanIndex] = this.loanObj;

        this.visibility2 = false;
        this.$refs.loanTable.refresh();
        // console.log(this.loanData);

        // console.log(this.loanObj);
        // var status = await this.post({
        //   url:
        //     this.$store.state.domain +
        //     "staff/Installments?db=" +
        //     this.$store.state.userData.db,
        //   body: this.loanObj,
        //   subjects: true,
        //   context: this,
        //   token: this.$store.state.userData.token,
        // });
        // this.request = false;
        // console.log(status);
        // if (status) {
        //   this.installData = status;
        // }

        // ------------------

        // if (this.loanObj.id == 0) {
        //   // var status = await this.post({
        //   //   url:
        //   //     this.$store.state.domain +
        //   //     " ?db=" +
        //   //     this.$store.state.userData.db,
        //   //   body: this.loanObj,
        //   //   message: "loan added successfully!",
        //   //   context: this,
        //   //   token: this.$store.state.userData.token,
        //   // });
        //   // this.request = false
        //   // if (status) {
        //   //   this.visibility2 = false,
        //   //   this.LoadLoan()
        //   // }
        // } else {
        //   console.log("ed", this.loanObj);

        //   //   var status = await this.put({
        //   //   url:
        //   //     this.$store.state.domain +
        //   //     " /" +
        //   //     item.id +
        //   //     "?db=" +
        //   //     this.$store.state.userData.db,
        //   //   body: this.loanObj,
        //   //   message: "Loan updated successfully!",
        //   //   context: this,
        //   //   token: this.$store.state.userData.token,
        //   // });
        //   // this.request = false
        //   // if (status) {
        //   //     this.visibility2 = false
        //   //     this.LoadLoan()
        //   //   }
        // }
        // this.request = false;
      }
    },

    hideSideBar() {
      this.visibility = false;
    },
    checkDate() {
      var elem = this.$refs["inc_date"];
      if (!this.incObj.date) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkAmount() {
      var elem = this.$refs["amount"];
      if (this.incObj.amount <= 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async LoadInc() {
      var obj = {
        url:
          this.$store.state.domain +
          " ?db=" +
          this.$store.state.userData.db +
          "&tID=" +
          this.$route.params.id,
        token: this.$store.state.userData.token,
      };
      this.history = await this.get(obj);
    },
    async Add() {
      if (this.checkAmount() == true && this.checkDate() == true) {
        // this.request = true;
        this.incObj.amount = parseInt(this.incObj.amount);
        // console.log(this.incObj);
        if (this.newInc) {
          this.history.push(this.incObj);
          if (this.incObj.updateSalary == "yes") {
            let x = parseInt(this.myObj.basicSalary);
            if (isNaN(x) || x <= 0) this.myObj.basicSalary = this.incObj.amount;
            else this.myObj.basicSalary = x + this.incObj.amount;

            let y = parseInt(this.myObj.totalSalary);
            if (isNaN(y) || y <= 0) this.myObj.totalSalary = this.incObj.amount;
            else this.myObj.totalSalary = y + this.incObj.amount;
          }
        } else this.history[this.incIndex] = this.incObj;
        // this.request = false;
        this.visibility = false;
        this.$refs.hisTable.refresh();
        // console.log(this.history);
        // if (this.incObj.id == 0) {
        //   // var status = await this.post({
        //   //   url:
        //   //     this.$store.state.domain +
        //   //     " ?db=" +
        //   //     this.$store.state.userData.db,
        //   //   body: this.incObj,
        //   //   message: "Increment added successfully!",
        //   //   context: this,
        //   //   token: this.$store.state.userData.token,
        //   // });
        //   // this.request = false
        //   // if (status) {
        //   //   this.visibility = false,
        //   //   this.LoadInc()
        //   // }
        // } else {
        //   console.log("ed", this.incObj);
        //   //   var status = await this.put({
        //   //   url:
        //   //     this.$store.state.domain +
        //   //     " /" +
        //   //     item.id +
        //   //     "?db=" +
        //   //     this.$store.state.userData.db,
        //   //   body: this.incObj,
        //   //   message: "Increment updated successfully!",
        //   //   context: this,
        //   //   token: this.$store.state.userData.token,
        //   // });
        //   // this.request = false
        //   // if (status) {
        //   //     this.visibility = false
        //   //     this.LoadInc()
        //   //   }
        // }
      }
    },

    deleteDocument(index) {
      this.documents.splice(index, 1);
      this.myObj.documents = this.documents.join(",");
    },
    uploadDocument() {
      let docs = this.$refs.docs.files;
      if (docs.length != 0) {
        this.uploading = true;
        let formData = new FormData();
        docs.forEach((el) => {
          formData.append("file", el);
        });
        var axios = require("axios");
        axios
          .post("https://upload.appick.io", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            let resp = e.data.myresp;
            resp.forEach((el) => {
              this.documents.push(el.path);
            });
            this.myObj.documents = this.documents.join(",");
            this.uploading = false;
            // console.log(this.myObj.documents);
          });
      }
    },

    validationForm() {
      return new Promise((resolve, reject) => {
        // resolve(true);
        this.CheckName();
        this.CheckSContact();

        if (this.CheckName() == false || this.CheckSContact() == false) {
          // console.log("invlaid");
          reject(false);
        } else {
          if (this.myObj.id == 0) {
            // console.log("val");
            resolve(true); //
          } else {
            resolve(true);
          }
        }
      });
    },
    validationFamilyInfo() {
      return new Promise((resolve, reject) => {
        resolve(true);
        // this.CheckCnic();
        // this.CheckMCnic();
        // this.CheckEmail();
        // if (
        //   this.CheckCnic() == false ||
        //   this.CheckMCnic() == false ||
        //   this.CheckEmail() == false
        // ) {
        //   reject(false);
        // } else {
        //   resolve(true);
        // }
      });
    },
    validationEmergency() {
      return new Promise((resolve, reject) => {
        resolve(true);
        // this.CheckEmerName();
        // this.CheckEmerRelation();
        // this.CheckEmerContact();
        // if (
        //   this.CheckEmerName() == false ||
        //   this.CheckEmerRelation() == false ||
        //   this.CheckEmerContact() == false
        // ) {
        //   reject(false);
        //   console.log("rej");
        // } else {
        //   resolve(true);
        // }
      });
    },
    deleteLogo() {
      //console.log(0);
      this.myObj.picture = null;
      this.logoloading = false;
      this.$refs.fileProfile.value = null;
    },
    selectlogo() {
      // console.log(this.$refs.fileProfile.files[0]);
      this.fileProfile = this.$refs.fileProfile.files[0];
      // console.log(this.fileProfile);
      if (this.fileProfile !== "") {
        this.logoloading = true;
        let formData = new FormData();
        var axios = require("axios");
        formData.append("file", this.fileProfile);
        axios
          .post("https://upload.appick.io/profile-picture", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            // this.file = undefined;
            // var fn1 = e.data.myresp[0].path;
            var fn1 = e.data.file.path;
            // console.log(fn1);
            this.plus = fn1;
            this.myObj.picture = this.plus;
            //
            // console.log(this.plus);
            //console.log(this.myObj.coverImg);
            this.logoloading = "loaded";
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    CheckName() {
      var elem = this.$refs["name"];
      if (this.myObj.name == "" || this.myObj.name < 3) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckCampus() {
      if (
        this.reportDomain == "myskoolhpgs" &&
        this.$store.state.userData.cId == 1
      ) {
        var elem = this.$refs["camp"];
        if (this.myObj.campusID == 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckAppointment() {
      var elem = this.$refs["appoint_date"];
      if (!this.myObj.appointedOn) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckFName() {
      var elem = this.$refs["fname"];
      if (this.myObj.father_name == "" || this.myObj.father_name < 3) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckQualification() {
      var elem = this.$refs["qua"];
      if (this.myObj.qualification == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDesignation() {
      var elem = this.$refs["design"];
      if (this.myObj.designation == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckFContact() {
      // console.log(this.myObj.contact_father);
      // console.log(this.myObj.contact_father.length);
      if (this.myObj.contact_father.length == 12) {
        this.fphoneerror = false;
        return true;
      } else {
        this.fphoneerror = true;
        return false;
      }
    },
    CheckMContact() {
      // console.log(this.myObj.contact_mother);
      // console.log(this.myObj.contact_mother.length);
      if (this.myObj.contact_mother.length == 12) {
        this.mphoneerror = false;
        return true;
      } else {
        this.mphoneerror = true;
        return false;
      }
    },
    CheckContact() {
      // console.log(this.myObj.contact_student);
      // console.log(this.myObj.contact_student.length);
      if (this.myObj.contact_student.length == 12) {
        this.phoneerror = false;
        return true;
      } else {
        this.phoneerror = true;
        return false;
      }
    },
    CheckSContact(s, e) {
      // console.log(this.myObj.contact_number);
      // console.log(this.myObj.contact_student.length);
      // if (this.myObj.contact_number && this.myObj.contact_number.length == 12) {
      //   this.sPhoneerror = false;
      //   return true;
      // } else {
      //   this.sPhoneerror = true;
      //   return false;
      // }

      if (this.myObj.contact_number) {
        if (e.valid) {
          this.sPhoneerror = false;
          this.myObj.contact_number = e.number;
          // console.log(this.myObj.contact_number);
        } else {
          this.sPhoneerror = true;
        }
      } else {
        this.sPhoneerror = false;
      }
    },

    CheckEmerName() {
      var elem = this.$refs["emerName"];
      if (
        this.myObj.emergency_name == null ||
        this.myObj.emergency_name == ""
      ) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkDep() {
      var elem = this.$refs["dep"];
      if (this.myObj.departmentID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckEmerRelation() {
      var elem = this.$refs["emerRel"];
      if (
        this.myObj.emergency_relationship == null ||
        this.myObj.emergency_relationship == ""
      ) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckEmerContact() {
      if (this.myObj.emergency_contact.length == 12) {
        // console.log(this.myObj.emergency_contact.length);
        this.emerPhoneerror = false;
        return true;
      } else {
        this.emerPhoneerror = true;
        return false;
      }
    },
    CheckFees() {
      const regex = /^(\d*([.,](?=\d{3}))?\d+)+((?!\2)[.,]\d\d)?$/;
      var elem = this.$refs["monthly_fee"];
      if (regex.test(this.myObj.monthly_fee)) {
        if (this.myObj.monthly_fee == 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else {
        return (elem.state = false);
      }
    },
    CheckGrNo() {
      var elem = this.$refs["grNo"];
      if (this.myObj.grNo == null || this.myObj.grNo == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckTcID() {
      var elem = this.$refs["tcID"];
      if (this.teachingClassObj.clsID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    setSections() {
      if (this.selectedSections.at(-1) == 0) {
        this.selectedSections = [0];
      } else {
        this.selectedSections = this.selectedSections.filter((el) => el != 0);
      }
      // console.log(this.selectedSections);
    },
    CheckTSection() {
      // console.log(this.teachingClassObj.sections.length);
      var elem = this.$refs["tSection"];
      if (this.selectedSections.length === 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    setSubjects() {
      if (this.selectedSubs.at(-1) == 0) {
        this.selectedSubs = [0];
      } else {
        this.selectedSubs = this.selectedSubs.filter((el) => el != 0);
      }
      // console.log(this.selectedSubs);
    },
    CheckTSubject() {
      // console.log(this.teachingClassObj.subjects.length);
      var elem = this.$refs["tSubjects"];
      if (this.selectedSubs.length === 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    FillSections(text) {
      // console.log(this.teachingClassObj.clsID);

      this.classes.forEach((elem) => {
        if (elem.cls.id === this.teachingClassObj.clsID) {
          if (elem.sections.length != 0) {
            // console.log("not null");
            this.admittedSections = [...elem.sections];
            this.admittedSections.unshift({ section: "All Sections", id: 0 });
          } else {
            // console.log("null");
            this.admittedSections = [];
          }
        }
      });
      if (text != "filled") this.selectedSections = [];
      // console.log("Thisis", this.admittedSections);

      if (this.subObj && this.subObj.valueBool) {
        this.selectedSubs = [];
        this.subjects = [];
        this.LoadByClass();
      }
    },
    async assign() {
      this.teachingClassObj.tID = parseInt(this.$route.params.id);
      // console.log(this.teachingClassObj);

      this.CheckTcID();
      this.CheckTSubject();
      this.CheckTSection();
      if (
        this.CheckTcID() == false ||
        this.CheckTSubject() == false ||
        this.CheckTSection() == false
      ) {
        return this.$bvToast.toast("Please enter all the required fields.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-right",
        });
      } else {
        this.assigning = true;

        if (this.selectedSections[0] == 0) {
          let secArr = this.classes.find(
            (el) => el.cls.id === this.teachingClassObj.clsID
          ).sections;
          let secs = secArr.reduce((acc, el) => {
            acc.push(el.id);
            return acc;
          }, []);
          this.teachingClassObj.sections = secs;
        } else {
          this.teachingClassObj.sections = this.selectedSections;
        }

        // console.log(this.teachingClassObj.sections);

        if (this.selectedSubs[0] == 0) {
          let subs = this.subjects.reduce((acc, el) => {
            if (el.id != 0) acc.push(el.id);
            return acc;
          }, []);
          this.teachingClassObj.subjects = subs;
        } else {
          this.teachingClassObj.subjects = this.selectedSubs;
        }
        // console.log(this.teachingClassObj.subjects);

        var status = await this.post({
          url:
            this.$store.state.domain +
            "TeacherClasses/SaveData?db=" +
            this.$store.state.userData.db,
          body: this.teachingClassObj,
          message: "Classes assigned successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) {
          this.LoadTeachingClasses();
          this.teachingClassObj = {
            tID: this.$route.params.id,
            clsID: 0,
            sections: [],
            subjects: [],
            campusID: this.$store.state.userData.cId,
          };
          this.selectedSections = [];
          this.selectedSubs = [];
        }
        this.assigning = false;
      }
    },
    FillSectionsCurrent() {
      //return console.log(this.classes);
      this.classes.forEach((elem) => {
        if (elem.id == this.myObj.class_current) {
          this.currentSections = elem.sections.split(",");
        }
      });
      // console.log("Thisis", this.admittedSections);
    },
    async LoadData(id) {
      // "staff/LoadSelectedStaff?db=" +
      var obj = {
        url:
          this.$store.state.domain +
          "staff/LoadSelectedStaffWithShare?db=" +
          this.$store.state.userData.db +
          "&id=" +
          id,
        token: this.$store.state.userData.token,
      };
      let res = await this.get(obj);
      //  console.log(res);
      this.myObj = res.staff;
      this.loanData = res.advanceAndLoans;
      this.loanDetails = res.loanDetails;
      this.deposits = res.securityDepositGrid;
      this.depoSaveList = res.securityDeposit;
      if (this.depoSaveList == null) this.depoSaveList = [];
      this.history = res.incrementHistory;

      this.AddingInfo = res.staffAllowances;
      if (this.AddingInfo.length == 0) {
        this.AddingInfo = [
          {
            id: 0,
            tID: this.$route.params.id,
            amount: 0,
            allowanceID: 0,
            campusID: this.$store.state.userData.cId,
          },
        ];
      } else this.changeAllow();

      this.deductInfo = res.staffDeductions;
      if (this.deductInfo.length == 0) {
        this.deductInfo = [
          {
            id: 0,
            tID: this.$route.params.id,
            amount: 0,
            deductionID: 0,
            campusID: this.$store.state.userData.cId,
          },
        ];
      } else this.changeDed();

      this.timingInfo = res.timings;
      if (this.timingInfo.length == 0) {
        this.timingInfo = [
          {
            id: 0,
            tID: this.$route.params.id,
            day: "monday",
            timeIn: "",
            timeOut: "",
            campusID: this.$store.state.userData.cId,
          },
        ];
      }
      this.changeDay();

      this.settingData = res.policy;
      this.setPolicy();

      if (this.myObj.picture !== null && this.myObj.picture !== "") {
        this.plus = this.myObj.picture;
        this.logoloading = "loaded";
      }
      if (this.myObj.documents) {
        this.documents = this.myObj.documents.split(",");
      }

      this.pClassData = res.classShare;
      this.LoadSettings("check");

      await this.LoadInfo();
      if (this.pClassData.length > 0) {
        this.pClassData.forEach((el, ind) => {
          this.FillPSec(el, ind, "filled");
        });
      }
    },
    setPolicy() {
      this.leavesObj = this.settingData.find(
        (el) => el.categoy == "Leave Allowed"
      );
      // console.log(this.leavesObj);
      this.lateObj = this.settingData.find(
        (el) => el.categoy == "Late Coming Scale"
      );
      // console.log(this.lateObj);
      this.shortObj = this.settingData.find(
        (el) => el.categoy == "Short Leave Scale"
      );
      // console.log(this.shortObj);
      this.attObj = this.settingData.find(
        (el) => el.categoy == "Attendance Allowance"
      );
      // console.log(this.attObj);
      this.encashObj = this.settingData.find(
        (el) => el.categoy == "Leave Encashment"
      );
      // console.log(this.encashObj);

      this.maxObj = this.settingData.find(
        (el) => el.categoy == "Max Leave per Month"
      );
      if (!this.maxObj) {
        this.maxObj = {
          id: 0,
          tID: this.$route.params.id,
          categoy: "Max Leave per Month",
          enabled: this.leavesObj.option == "Yearly",
          number: 0,
          option: "",
          campusID: this.$store.state.userData.cId,
        };
      }
      // console.log(this.maxObj);
      this.minuteObj = this.settingData.find(
        (el) => el.categoy == "Late Coming Deduction per Minute"
      );
      if (!this.minuteObj) {
        this.minuteObj = {
          id: 0,
          tID: this.$route.params.id,
          categoy: "Late Coming Deduction per Minute",
          enabled: false,
          number: 0,
          option: "",
          campusID: this.$store.state.userData.cId,
        };
      }
      // console.log(this.minuteObj);
      this.earlyObj = this.settingData.find(
        (el) => el.categoy == "Early Departure"
      );
      if (!this.earlyObj) {
        this.earlyObj = {
          id: 0,
          tID: this.$route.params.id,
          categoy: "Early Departure",
          enabled: false,
          number: 0,
          option: "No",
          campusID: this.$store.state.userData.cId,
        };
      }
      // console.log(this.earlyObj);
    },
    setMaxLeaves() {
      if (this.leavesObj.option == "Yearly") {
        this.maxObj.enabled = true;
      } else this.maxObj.enabled = false;
      // console.log(this.maxObj);
    },
    async LoadInfo() {
      this.dataloading = true;

      var obj = {
        url:
          this.$store.state.domain +
          "myclasses/LoadClasses?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&dID=0",
        token: this.$store.state.userData.token,
      };
      this.classes = await this.get(obj);
      this.classesOptions = this.classes.map((el) => el.cls);

      this.LoadDep();
      this.dataloading = false;
    },
    async LoadDep() {
      var obj = {
        url:
          this.$store.state.domain +
          "StaffDepartments?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.deptOptions = await this.get(obj);
      // console.log("dept", this.deptOptions);
    },
    async LoadSettings(text) {
      let res = await this.post({
        url:
          this.$store.state.domain +
          "Settings/getSettings?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        body: ["subject_class", "payroll_share"],
        context: this,
        token: this.$store.state.userData.token,
        subjects: true,
      });
      this.subObj = res.find((el) => el.key == "subject_class");
      this.pObj = res.find((el) => el.key == "payroll_share");

      if (this.pObj && this.pObj.valueBool) {
        this.LoadPSubs();
        if (text == "check" && this.pClassData.length > 0) {
          this.payrollByShare = true;
        }
      }

      if (this.subObj && this.subObj.valueBool) {
        this.subjects = [];
      } else this.LoadSubject();
    },
    async LoadSubject() {
      var obj = {
        url:
          this.$store.state.domain +
          "Subjects?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.subjects = await this.get(obj);
      if (this.subjects.length > 0) {
        this.subjects.unshift({ subject: "All Subjects", id: 0 });
      }

      // console.log(this.subjects);
    },
    async LoadByClass() {
      var obj = {
        url:
          this.$store.state.domain +
          "Subjects/LoadSubjectClassData?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&clsID=" +
          this.teachingClassObj.clsID,
        token: this.$store.state.userData.token,
      };
      this.subjects = await this.get(obj);
      if (this.subjects.length > 0) {
        this.subjects.unshift({ subject: "All Subjects", id: 0 });
      }
      // console.log(this.subjects);
    },
    async LoadTeachingClasses() {
      // var axios = require("axios");
      // var config = {
      //   method: "get",
      //   url:
      //     this.$store.state.domain +
      //     "TeacherClasses/LoadData?db=" +
      //     this.$store.state.userData.db +
      //     "&tID=" +
      //     this.$route.params.id,
      //   headers: {
      //     Authorization: "bearer " + this.$store.state.userData.token,
      //   },
      // };

      // axios(config)
      //   .then((response) => {
      //     console.log(response.data);
      //     this.teachingClasses = response.data.data;
      //     console.log(this.teachingClasses);
      //     this.newTeachingList = [];
      //     this.teachingClasses.forEach((el) => {
      //       el.secSubjectsData.forEach((sec) => {
      //         let data = {
      //           clsID: el.cID,
      //           className: el.className,
      //           sections: [sec.sectionID],
      //           sectionName: sec.sectionName,
      //           subjects: sec.subjects,
      //         };
      //         this.newTeachingList.push(data);
      //       });
      //     });
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //   });
      var obj = {
        url:
          this.$store.state.domain +
          "TeacherClasses/LoadDataWebNew?db=" +
          this.$store.state.userData.db +
          "&tID=" +
          this.$route.params.id,
        token: this.$store.state.userData.token,
      };
      this.gridData = await this.get(obj);
      // console.log("grid", this.gridData);
    },
    EditTeaching(item) {
      // console.log(item);
      let subIDs = [];
      this.subjects.forEach((sb) => {
        item.subjects.forEach((el) => {
          if (el.subject == sb.subject) {
            subIDs.push(sb.id);
          }
        });
      });
      this.teachingClassObj = {
        tID: this.$route.params.id,
        clsID: item.clsID,
        sections: [item.secID],
        subjects: subIDs,
        campusID: this.$store.state.userData.cId,
      };

      // this.teachingClassObj.subjects = subIDs;
      // this.teachingClassObj.clsID = item.clsID;
      // item.subjects.forEach((el) => this.teachingClassObj.subjects.push(el.id));
      // this.teachingClassObj.sections = item.sections;
      this.FillSections("filled");
      console.log(this.teachingClassObj);
    },
    async DeleteTeaching(item) {
      // console.log(item);
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.post({
          url:
            this.$store.state.domain +
            "TeacherClasses/Remove?db=" +
            this.$store.state.userData.db,
          body: item,
          message: "Teaching classes removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadTeachingClasses();
      }
    },
    async DeleteSub(item) {
      // console.log(item);
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "Staff/DeleteSubjects?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&clsID=" +
            item.clsID +
            "&secID=" +
            item.secID +
            "&tID=" +
            item.tID,
          body: null,
          message: "subject removed successfully!",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadTeachingClasses();
      }
    },
    async removeSingle(row, sub) {
      // console.log(row, sub);
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "TeacherClasses/" +
            sub.id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          message: "subject removed successfully!",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadTeachingClasses();
      }
      // let filteredSubs = row.subjects.filter((el) => el !== sub);
      // console.log(filteredSubs);

      // let subIDs = [];
      // this.subjects.forEach((sb) => {
      //   filteredSubs.forEach((el) => {
      //     if (el == sb.subject) {
      //       subIDs.push(sb.id);
      //     }
      //   });
      // });

      // console.log(subIDs);

      // let saveObj = {
      //   tID: row.tID,
      //   clsID: row.clsID,
      //   sections: [row.secID],
      //   subjects: subIDs,
      //   campusID: this.$store.state.userData.cId,
      // };
      // console.log(saveObj);

      // var status = await this.post({
      //   url:
      //     this.$store.state.domain +
      //     "TeacherClasses/SaveData?db=" +
      //     this.$store.state.userData.db,
      //   body: saveObj,
      //   message: "Classes assigned successfully.",
      //   context: this,
      //   token: this.$store.state.userData.token,
      // });
      // if (status) {
      //   this.LoadTeachingClasses();
      // }
    },
    setHalfHint() {
      var elem = this.$refs["shortScale"];
      var re = /^[0-9]+$/;

      if (this.shortObj.number != "" && this.shortObj.number != 0) {
        if (re.test(this.shortObj.number) && this.shortObj.number > 0) {
          this.showHalfHint = true;
          return (elem.state = true);
        } else {
          this.showHalfHint = false;
          return (elem.state = false);
        }
      } else {
        this.showHalfHint = false;
        return (elem.state = false);
      }
    },
    setLateHint() {
      var elem = this.$refs["lateScale"];
      if (this.lateObj.number != "" && this.lateObj.number != 0) {
        var re = /^[0-9]+$/;

        if (re.test(this.lateObj.number) && this.lateObj.number > 0) {
          this.showLateHint = true;
          return (elem.state = true);
        } else {
          this.showLateHint = false;
          return (elem.state = false);
        }
      } else {
        this.showLateHint = false;
        return (elem.state = false);
      }
    },

    checkAllowed() {
      if (this.leavesObj.enabled) {
        var elem = this.$refs["all_cat"];
        if (this.leavesObj.option == "") {
          return (elem.state = false);
        } else {
          elem.state = true;
          if (this.checkLeaves() == true) {
            if (this.leavesObj.option == "Yearly") return this.checkMaxLeave();
          } else return false;
        }
      } else return true;
    },
    checkLeaves() {
      var elem = this.$refs["leave_allowed"];
      var re = /^[0-9]+$/;
      let x = parseInt(this.leavesObj.number);
      if (!re.test(this.leavesObj.number) || isNaN(x) || x < 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkSalary() {
      var elem = this.$refs["salary"];
      var re = /^[0-9]+$/;
      let x = parseInt(this.myObj.totalSalary);
      if (!re.test(this.myObj.totalSalary) || isNaN(x) || x < 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkShare() {
      if (this.reportDomain == "myskoolskh" && this.myObj.sharePerc) {
        var elem = this.$refs["share"];
        let x = parseInt(this.myObj.sharePerc);
        if (isNaN(x) || x < 0 || x > 100) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      }
      return true;
    },
    checkMaxLeave() {
      var elem = this.$refs["max_allowed"];
      var re = /^[0-9]+$/;
      let x = parseInt(this.maxObj.number);
      if (
        !re.test(this.maxObj.number) ||
        isNaN(x) ||
        x <= 0 ||
        x > this.leavesObj.number
      ) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkLate() {
      if (this.lateObj.enabled) {
        return this.setLateHint();
      } else return true;
    },
    checkShort() {
      if (this.shortObj.enabled) {
        return this.setHalfHint();
      } else return true;
    },
    checkFields() {
      setTimeout(() => {
        this.checkAtt();
      }, 200);
    },
    checkAtt() {
      if (this.attObj.enabled) {
        if (this.attObj.option == "Fixed amount") return this.checkAmountAtt();
        else if (this.attObj.option == "Percentage") return this.checkPerc();
        // else return this.checkDays();
        else return true;
      } else return true;
    },
    checkAmountAtt() {
      var elem = this.$refs["amount"];
      var re = /^[0-9]+$/;
      let x = parseInt(this.attObj.number);
      if (!re.test(this.attObj.number) || isNaN(x) || x < 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkPerc() {
      var elem = this.$refs["percentage"];
      // let x = parseFloat(this.attObj.number);
      var re = /^[0-9]+$/;
      let x = parseInt(this.attObj.number);
      if (!re.test(this.attObj.number) || isNaN(x) || x < 0 || x > 100) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkMax() {
      if (this.encashObj.enabled) {
        var elem = this.$refs["maxEn"];
        var re = /^[0-9]+$/;
        let x = parseInt(this.encashObj.number);
        if (
          !re.test(this.encashObj.number) ||
          isNaN(x) ||
          x <= 0 ||
          x > this.leavesObj.number
        ) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    checkPerMin() {
      if (this.minuteObj.enabled) {
        var elem = this.$refs["lateAmount"];
        var re = /^[0-9]+$/;
        let x = parseInt(this.minuteObj.number);
        if (!re.test(this.minuteObj.number) || isNaN(x) || x < 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      }
      return true;
    },
    setEarlyHint() {
      var elem = this.$refs["earlyScale"];
      if (this.earlyObj.number != "" && this.earlyObj.number != 0) {
        var re = /^[0-9]+$/;

        if (re.test(this.earlyObj.number) && this.earlyObj.number > 0) {
          this.showEarly = true;
          return (elem.state = true);
        } else {
          this.showEarly = false;
          return (elem.state = false);
        }
      } else {
        this.showEarly = false;
        return (elem.state = false);
      }
    },
    checkEarly() {
      if (this.earlyObj.enabled) {
        return this.setEarlyHint();
      } else return true;
    },
    checkPayrollShare() {
      let state = true;
      if (this.pObj && this.pObj.valueBool && this.payrollByShare) {
        this.pClassData.forEach((el) => {
          let x = parseInt(el.baseValue);
          let y = parseInt(el.sharePerStudent);
          if (
            el.cls == 0 ||
            el.secID == 0 ||
            el.subjectID == 0 ||
            !el.startingFrom ||
            isNaN(x) ||
            x < 0 ||
            isNaN(y) ||
            y <= 0
          ) {
            state = false;
          } else {
            el.baseValue = x;
            el.sharePerStudent = y;
          }
        });
      } else state = false;
      return state;
    },
    async formSubmitted() {
      this.submitting = true;
      this.CheckCampus();
      this.CheckName();
      this.checkDep();
      this.CheckAppointment();
      this.checkSalary();
      // this.CheckSContact();
      if (this.CheckCampus() == false) {
        this.submitting = false;
        return this.$bvToast.toast("Please select the campus", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
      } else if (this.checkDep() == false) {
        this.submitting = false;
        return this.$bvToast.toast("Please select staff department.", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
      } else if (
        this.CheckName() == false
        // this.CheckSContact() == false
        // this.checkDep() == false ||
        // this.CheckValues() == false ||
        // this.CheckTimings() == false
      ) {
        this.submitting = false;
        return this.$bvToast.toast("Invalid name entered!", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
      } else if (this.CheckAppointment() == false) {
        this.submitting = false;
        return this.$bvToast.toast("Please enter appointment date!", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
      }
      // else if (this.sPhoneerror) {
      //   this.submitting = false;
      //   return this.$bvToast.toast("Invalid contact entered!", {
      //     title: "Error!",
      //     variant: "danger",
      //     solid: true,
      //   });
      // }
      else if (this.CheckTimings() == false) {
        this.submitting = false;
        return this.$bvToast.toast("Please enter the timings correctly!", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
      } else if (this.checkSalary() == false) {
        this.submitting = false;
        return this.$bvToast.toast("Please enter the salary correctly!", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
      } else if (this.checkShare() == false) {
        this.submitting = false;
        return this.$bvToast.toast("Please enter the share correctly!", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
      } else if (
        this.CheckValues() == false ||
        this.checkAllowed() == false ||
        this.checkLate() == false ||
        this.checkShort() == false ||
        this.checkAtt() == false ||
        this.checkMax() == false ||
        this.checkPerMin() == false ||
        this.checkEarly() == false
      ) {
        this.submitting = false;
        return this.$bvToast.toast(
          "Please enter the payroll details correctly!",
          {
            title: "Error!",
            variant: "danger",
            solid: true,
          }
        );
      } else {
        this.request = true;

        this.myObj.totalSalary = parseInt(this.myObj.totalSalary);
        if (this.myObj.basicSalary != "" && this.myObj.basicSalary != null) {
          this.myObj.basicSalary = parseInt(this.myObj.basicSalary);
        } else this.myObj.basicSalary = 0;

        if (!this.myObj.sharePerc) {
          this.myObj.sharePerc = 0;
        } else {
          this.myObj.sharePerc = parseInt(this.myObj.sharePerc);
        }
        // console.log("!!!!!!!!!!!!!", this.myObj);
        if (this.myObj.date_of_birth == "") {
          this.myObj.date_of_birth = null;
        }
        if (this.myObj.appointedOn == "") {
          this.myObj.appointedOn = null;
        }
        if (this.myObj.leaves_allowed_from == "") {
          this.myObj.leaves_allowed_from = null;
        }
        if (this.myObj.appointedOn && !this.myObj.leaves_allowed_from) {
          this.myObj.leaves_allowed_from = this.myObj.appointedOn;
        }
        if (this.myObj.leaving_date == "") {
          this.myObj.leaving_date = null;
        }
        if (this.myObj.rejoining_date == "") {
          this.myObj.rejoining_date = null;
        }

        // policy parsing
        if (!this.leavesObj.number) this.leavesObj.number = 0;
        else this.leavesObj.number = parseInt(this.leavesObj.number);
        if (!this.lateObj.number) this.lateObj.number = 0;
        else this.lateObj.number = parseInt(this.lateObj.number);
        if (!this.shortObj.number) this.shortObj.number = 0;
        else this.shortObj.number = parseInt(this.shortObj.number);
        if (!this.attObj.number) this.attObj.number = 0;
        else this.attObj.number = parseInt(this.attObj.number);
        if (!this.encashObj.number) this.encashObj.number = 0;
        else this.encashObj.number = parseInt(this.encashObj.number);
        if (!this.maxObj.number) this.maxObj.number = 0;
        else this.maxObj.number = parseInt(this.maxObj.number);
        if (!this.minuteObj.number) this.minuteObj.number = 0;
        else this.minuteObj.number = parseInt(this.minuteObj.number);
        if (!this.earlyObj.number) this.earlyObj.number = 0;
        else this.earlyObj.number = parseInt(this.earlyObj.number);

        let newLoadDet = this.loanData.reduce((acc, el) => {
          if (el.records) {
            acc = [...acc, ...el.records];
          }
          return acc;
        }, []);
        // console.log(newLoadDet);

        let saveObj = {
          staff: this.myObj,
          timings: this.timingInfo,
          incrementHistory: this.history,
          staffAllowances: this.AddingInfo,
          staffDeductions: this.deductInfo,
          advanceAndLoans: this.loanData,
          securityDeposit: this.depoSaveList,
          policy: [
            this.leavesObj,
            this.lateObj,
            this.shortObj,
            this.attObj,
            this.encashObj,
            this.maxObj,
            this.minuteObj,
            this.earlyObj,
          ],
          loanDetails: [...this.loanDetails, ...newLoadDet],
          securityDepositGrid: this.deposits,
          classShare: this.checkPayrollShare() ? this.pClassData : [],
        };
        // console.log(saveObj);
        var message = "Staff added successfully.";
        if (this.myObj.id > 0) message = "Staff updated successfully.";

        // "staff/SaveWithPolicy?db=" +
        var status = await this.postObj({
          url:
            this.$store.state.domain +
            "staff/SaveWithShare?db=" +
            this.$store.state.userData.db,
          body: saveObj,
          message: message,
          context: this,
          token: this.$store.state.userData.token,
        });
        this.submitting = false;
        if (this.myObj.id == 0) {
          if (status.staff.id > 0) {
            var domain =
              window.location.pathname.split("/")[0] +
              "/" +
              window.location.pathname.split("/")[1] +
              "/" +
              status.staff.id;

            window.open(domain, "_self");
          }
        } else {
          this.$router.push("/manage-staff");
        }
      }
    },
  },
};
</script>
<style>
.heading {
  padding: 0;
  margin: 2.171303074670571vw 0;
  /* text-shadow: -4px 0 5px white, 0 4px 5px white, 4px 0 5px white,
      0 -4px 5px white; */
}
.input-form {
  margin: 0 70px;
  background: #fff;
}
#fileInput .custom-file-label {
  color: #707070;
  border: 1px dashed #33312f;
  text-align: center;
  padding: 2.5rem 1.5rem;
  width: 100%;
}
#fileInput input {
  display: none;
}
#imgInput input {
  display: none;
}
#imgInput {
  /* height: 8rem; */
  width: 50%;
}
#imgInput div {
  height: 8vw;
}
#imgInput .custom-file-label {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.type {
  background-color: red;
  border-radius: 10px;
  font-size: 0.8rem;
  padding: 10px;
  color: white;
}
.filename {
  padding-left: 25px;
}
.delete {
  margin: 0 0 0 auto;
  color: red;
}
#checkbox-1 {
  margin-right: 10px !important;
}
.submit {
  background-color: #00c192;
  color: #fff;
  width: 16.5vw;
  height: 4vw;
  font-weight: bold;
  border-color: #00c192;
}
.form-file-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 20px;
}
/* #prop-det, #prop-det div {
    height: 100%;
  } */

.item-group {
  width: 50%;
  /* margin-top: 2.269399707174231vw; */
}
.inputfields,
.inputfields:focus {
  border: 1px solid #33312f;
  border-radius: 0.5856515373352855vw;
  height: 2.562225475841874vw;
  outline: none !important;
  resize: none;
}
textarea.form-control {
  height: 8vw !important;
}
@media only screen and (max-width: 990px) {
  .mob-setting {
    padding: 0 1rem !important;
    width: 100vw !important;
    margin: 0 auto !important;
  }
  .mobile-view {
    margin: 0 !important;
  }
  /* .col-form-label,
  .form-file-text,
  .custom-control-label {
    font-size: 3vw;
  } */
  .item-group {
    margin-top: 0;
  }
  .margin-top-mobile {
    margin-top: 5vw;
  }
  /* .col-form-label {
    display: none;
  } */
  .form-input-mobile-text {
    width: 92vw;
    margin: 10px auto;
    border-radius: 2.8301610541727673vw;
    height: 12.781844802342606vw;
    font-size: 5.171303074670571vw;
    background-color: #f2f2f2 !important;
    border: 1px solid #33312f;
  }
  textarea.form-control {
    height: 12.781844802342606vw !important;
  }
  .form-input-mobile-text:focus {
    background-color: #00c192 !important;
    color: #fff !important;
  }
  .form-input-mobile-text::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgb(190, 190, 190);
    opacity: 1; /* Firefox */
    font-family: DINNextRoundedLTW01-Regular;
  }

  .form-input-mobile-text:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgb(190, 190, 190);
  }

  .form-input-mobile-text::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgb(190, 190, 190);
  }

  .form-input-mobile-text:focus::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff;
  }
  .form-file-input-mobile {
    border-radius: 2.8301610541727673vw;
    height: 12.781844802342606vw;
    background-color: #f2f2f2 !important;
    color: #fff !important;
  }
  .custom-file-label {
    border-radius: 2.8301610541727673vw !important;
    height: 12.781844802342606vw;
    background-color: #f2f2f2 !important;
    color: #000 !important;
    height: 40vw;
    border: 1px solid #33312f;
  }
  .submit {
    width: 50vw;
    height: 10vw;
  }
  .filename {
    font-size: 3vw;
  }
  .shadowHead {
    font-size: 8vw !important;
  }
  #imgInput div {
    height: 29vw !important;
  }
  .item-group {
    height: 30vw !important;
  }
}

@media only screen and (min-width: 1366px) {
  .submit {
    width: 15.5vw;
    height: 7.8vh;
  }
}
@media only screen and (min-width: 1440px) {
  .submit {
    width: 14.7vw;
    height: 6.7vh;
  }
}
@media only screen and (min-width: 1536px) {
  .submit {
    width: 13.8vw;
    height: 6.9vh;
  }
}
@media only screen and (min-width: 1920px) {
  .submit {
    width: 11vw;
    height: 5.5vh;
  }
}
#overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}
</style>
<style scoped>
.rcorners2 {
  border-radius: 100px;
  border: 4px solid var(--primary);
  padding: 2px;
}
.sticky {
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 60px;
  margin-bottom: 30px;
  /* position: fixed;
      bottom: 5%;
      z-index: 1031;  */
}

.file-uploader {
  position: relative;
  border: 1px solid #000;
  width: 100%;
  line-height: 30px;
  text-align: center;
  border-radius: 10px;
  font-family: "Helvetica", sans-serif;
  height: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.file_upload {
  opacity: 0;

  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

  /* IE 5-7 */
  filter: alpha(opacity=0);

  /* Netscape or and older firefox browsers */
  -moz-opacity: 0;

  /* older Safari browsers */
  -khtml-opacity: 0;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.file {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.file-type {
  background-color: #e62e2d;
  font-family: "Helvetica", sans-serif;
  color: white;
  border-radius: 10px;
  margin-right: 15px;
  height: 50px;
  width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.file-name {
  font-family: "Helvetica", sans-serif;
  margin: 0px;
}

.file-delete i {
  color: #e62e2d;
  font-size: 2rem;
  cursor: pointer;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
